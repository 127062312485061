import React, { useEffect } from "react";
import { Container, StyledItem } from "./styles";

interface Props {
  items: any[];
  className?: string;
}

const CartList = ({ items, className }: Props) =>
  items.length > 0 ? (
    <Container className={className}>
      {items.map((item) => (
        <StyledItem totalItems={items?.length} key={item.id} loadOldImage={true} {...item} />
      ))}
    </Container>
  ) : null;

export default CartList;
