import styled from "styled-components";
import Image from "src/components/Common/ImageFluid";
import { SectionHeading } from "../Text";
import GatsbyLink from "gatsby-link";

interface LinkProps {
  disabled?: boolean;
}

export const ContainerLink = styled(GatsbyLink)`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 10.9rem;
  background-color: ${({ theme }) => theme.colors.bg_white};
`;

export const StyledImage = styled(Image)`
  width: 100%;
  max-width: 13.5rem;
  object-fit: cover;
`;

export const Title = styled(SectionHeading)`
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 0.9rem;
`;

export const InfoBox = styled.div`
  /* padding: 1.2rem 2rem 1.5rem; */
  padding: .4rem;
  display: flex;
  flex-direction: column;
`;

export const PriceBox = styled.div``;

export const Price = styled(SectionHeading)`
  font-size: 1.2rem;
  line-height: 1.4rem;
`;

export const OldPrice = styled(Price)``;

export const Link = styled(SectionHeading)<LinkProps>`
  cursor: pointer;
  margin-top: auto;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

// export const Link = styled()
