import React, { useEffect, useState } from "react";
import {
  Footer,
  Subeading,
  Heading,
  FooterRow,
  Container,
  CheckoutButton,
  EmptyContainer,
  EmptyHeading,
  EmptySubheading,
  StyledProductList,
  EmptyCheckoutButton,
  ProgressBar,
  ProgressContainer,
  ProgressBarSubheading,
  FreeShippingHeading,
  CaseContainer,
  Tag,
  TagIcon,
  DiscountCodeText,
  RemoveDiscountBtn,
  DiscountContainer,
  DiscountInput,
  AddDiscountButton,
  Loader,
  DiscountForm,
  DiscountError,
  TagContainer,
  TagWarning,
  Code,
  WarningText,
  CaseAccordionHeader, 
  CaseHeader, 
  CaseSubheader, 
  IsActive, 
  GridUpsells, 
} from "./styles";
import {
  useReviewDrawer,
  useCart,
  useCheckout,
  useDiscount,
} from "src/context/siteContext";
import CartList from "src/components/CartList";
import ShippingIndicator from "src/components/ShippingIndicator";
import { Product } from "src/interfaces/sanity";
import { SmallDesktop } from "src/components/Responsive";
import MobileUpsells from "src/components/MobileUpsells";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import CaseCard from "src/components/CaseCard";
import { SanityImage } from "src/interfaces/sanity";
import Icon from "../Common/Icon";
import PortableText from '@sanity/block-content-to-react';
import chevronDown from 'src/images/chevron-down-2.svg';
import { Subheading } from "src/containers/HeadingSection/styles";
import locale from 'src/locales';

interface Props {
  info: {
    freeShippingMin: number;
    noFreeShipping?: boolean;
    upsellProducts?: Product[];
    textCopies?: {
      emptyCart: string;
      freeShipping: string;
      shippingCalculated: string;
      shippingHeading: string;
      subtotalHeading: string;
      freeShippingUnlocked: string;
    };
    emptyButtonLink?: string;
    emptyButtonText?: string;
  };
  productCases?: any;
  caseText?: any;
  caseSubheader?: any;
  caseImage?: SanityImage;
  caseTitle?: string;
  caseDescription?: string;
  caseSubtitle?: string;
}

interface DiscountInterface {
  input: string;
  loading: boolean;
  error: boolean;
}

const ga4Items = (lineItems: any) => {
  return lineItems?.map((item: any, idx: number) => {
    const { variant } = item;
    return {
      item_id: variant.sku,
      item_name: item.title,
      currency: process.env.GATSBY_CURRENCY_SYMBOL == "$" ? "USD" : "GBP",
      index: idx + 1,
      item_brand: "GroovePillows",
      item_variant: variant.title,
      price: variant.priceV2?.amount,
      quantity: item.quantity,
    };
  });
};

const Cart = ({
  info,
  productCases,
  caseImage,
  caseTitle,
  caseDescription,
  caseSubtitle,
  caseSubheader,
  caseText
}: Props) => {
  const {
    checkout: { lineItems, discount, total, freeShipping },
  } = useCart();
  const [discountState, setDiscountState] = useState<DiscountInterface>({
    input: "",
    loading: false,
    error: false,
  });
  const openCheckout = useCheckout();
  const { addDiscount, removeDiscount } = useDiscount();

  const beginCheckout = () => {
    dataLayer?.push({
      event: "begin_checkout",
      ecommerce: {
        items: ga4Items(lineItems),
      },
    });
    openCheckout();
  };

  const { closeReviews } = useReviewDrawer();
  const breakpoints = useBreakpoint();
  const [isActive, setIsActive] = useState(true);

  const {
    freeShippingMin,
    noFreeShipping,
    upsellProducts,
    textCopies,
    emptyButtonLink,
    emptyButtonText,
  } = info;
  const pricedShip = parseFloat(total) <= freeShippingMin;

  const onDiscountSubmit = async (e: Event) => {
    e.preventDefault();
    setDiscountState((actualDiscount) => ({
      ...actualDiscount,
      error: false,
      loading: true,
    }));
    try {
      const updatedCart = await addDiscount(discountState.input);
      const updatedDiscounts = updatedCart.discountApplications;
      if (updatedDiscounts.length !== 0) {
        const code = updatedDiscounts[0]?.code || updatedDiscounts[1]?.code;
        setDiscountState((actualDiscount) => ({
          ...actualDiscount,
          error: code !== actualDiscount.input.toUpperCase(),
        }));
      } else {
        setDiscountState((actualDiscount) => ({
          ...actualDiscount,
          error: true,
        }));
      }
    } catch (err) {
      setDiscountState((actualDiscount) => ({
        ...actualDiscount,
        error: true,
      }));
    } finally {
      setDiscountState((actualDiscount) => ({
        ...actualDiscount,
        loading: false,
        input: "",
      }));
    }
  };

  const onDiscountChange = (e: any) => {
    setDiscountState((actualDiscount) => ({
      ...actualDiscount,
      input: e?.target?.value || "",
      error: actualDiscount.error ? false : actualDiscount.error,
    }));
  };

  useEffect(() => {
    if (lineItems?.length > 0) {
      dataLayer?.push({
        event: "view_cart",
        cart_total: total,
        currencyCode: process.env.GATSBY_CURRENCY_SYMBOL == "$" ? "USD" : "GBP",
        ecommerce: {
          items: ga4Items(lineItems),
        },
      });
    }
  }, []);

  const isUK = process.env.GATSBY_CURRENCY_SYMBOL === "£";

  const UpsellProducts = ({ id = "", heading = false, showBtn = false }) => {
    let cutProducts: Product[] = [];

    if (upsellProducts!.length > 0) {
      cutProducts = upsellProducts!.slice(0, 3);
    }


    return (
      <div id={id ?? "upsell-products"}>{
        breakpoints.smallDesktop ? (
          <>
            {heading && <EmptySubheading>Let’s add something.</EmptySubheading>}
            {cutProducts.length > 0 && (
              <StyledProductList
                products={cutProducts}
                addToCart={true}
                onClick={closeReviews}
              />
            )}
            {showBtn && emptyButtonLink && emptyButtonText && (
              <EmptyCheckoutButton to={emptyButtonLink} onClick={closeReviews}>
                {emptyButtonText}
              </EmptyCheckoutButton>
            )}
          </>
        ) : (
          <MobileUpsells
            products={upsellProducts}
            heading="Let's add something"
          />
        )
      }</div>)
  }

  const renderEmpty = () => {
    return (
      <EmptyContainer>
        <EmptyHeading>{textCopies.emptyCart}</EmptyHeading>
        <UpsellProducts heading={true} showButton={true} />
      </EmptyContainer>
    );
  };

  const productCasesIds: string[] = productCases?.map(
    (prod: any) => `${prod?.content?.shopify?.defaultVariant?.variantIdShopify}`
  );
  const newLineItems = lineItems?.filter(
    (item: any) =>
      !productCasesIds?.includes((item?.variant?.id))
  );

  const freeNeckPainId = "gid://shopify/ProductVariant/40074074456150";
  const neckPainProduct = lineItems?.find(item => item?.variant?.id === freeNeckPainId);
  const neckPainProductPrice = neckPainProduct?.variant?.price?.amount;

  return (
    <Container empty={!lineItems?.length > 0}>
      {!noFreeShipping && 
      <ShippingIndicator
        minPrice={freeShippingMin}
        total={total}
        items={lineItems?.length}
        freeShipping={freeShipping}
      />}

      {lineItems?.length > 0 ? (
        <>
          <CartList items={lineItems} />

          <GridUpsells id="grid-upsell">

          {productCases?.length > 0 && (
            <>
     
            <CaseAccordionHeader onClick={() => setIsActive(!isActive)}>
             
                 {caseText && (
                  <>
                    <CaseHeader>
                      <div className="header-subheader">
                        <span>{caseText}</span>
                        {caseSubheader && (
                          <CaseSubheader>
                            <PortableText blocks={caseSubheader} />
                          </CaseSubheader>
                        )}
                      </div>
                    </CaseHeader>
                  </>
                )} 
                <IsActive src={chevronDown} isActive={isActive} />
              </CaseAccordionHeader>
              {isActive && (
                <CaseContainer>
                  {productCases.map((pillow: any, idx: number) => {
                    const variantId =
                      pillow?.content?.shopify?.defaultVariant?.variantIdShopify;
                    let quantity = 0;
                    const itemInCart = lineItems?.find(
                      (item) =>
                        (item?.variant?.id) == variantId
                    );
                    if (itemInCart) {
                      quantity = itemInCart.quantity;
                    }
                    const canAdd =
                      pillow?.content?.shopify?.defaultVariant
                        ?.inventoryQuantity > 0;
              

                    return (
                      <CaseCard
                        key={idx}
                        pillow={pillow}
                        canAdd={canAdd}
                        quantity={quantity}
                        lineItems={lineItems}
                        caseImage={caseImage}
                        caseDescription={caseDescription}
                        caseTitle={caseTitle}
                        caseSubtitle={caseSubtitle}
                      />
                    );
                  })}
                </CaseContainer>
              )}
            </>
          )}
          </GridUpsells>

          <UpsellProducts id="upsell-products-carousel" />

          <DiscountContainer
            id="discount-form"
            onSubmit={(e) => onDiscountSubmit(e)}
          >
            <DiscountForm>
              <DiscountInput
                value={discountState.input}
                error={discountState.error}
                onChange={(e) => onDiscountChange(e)}
                type="text"
                placeholder="Gift card or discount code"
              />
              <AddDiscountButton type="submit" disabled={!discountState.input}>
                {discountState.loading ? <Loader /> : "Apply"}
              </AddDiscountButton>
            </DiscountForm>
            {discountState.error && (
              <DiscountError>
                Enter a valid discount code or gift card
              </DiscountError>
            )}
          </DiscountContainer>
          {!isUK && discount?.has && (
            <TagContainer id="discount-tag">
              <Tag>
                <TagIcon>
                  <Icon name="tag" />
                </TagIcon>
                <DiscountCodeText>
                  {discount?.code}{" "}
                  {discount?.amount !== 0 && (
                    <span>
                      -{process.env.GATSBY_CURRENCY_SYMBOL}
                      {discount?.amount}
                    </span>
                  )}
                </DiscountCodeText>
                <RemoveDiscountBtn onClick={removeDiscount}>
                  <Icon name="close-thick" />
                </RemoveDiscountBtn>
              </Tag>
              {!discount?.applicable && !freeShipping && discount?.amount !== 0 && (
                <TagWarning>
                  <Icon name="warning" />
                  <WarningText>
                    <Code>{discount?.code}</Code> code isn't valid for the items
                    in your cart
                  </WarningText>
                </TagWarning>
              )}
            </TagContainer>
          )}
          

          <Footer>
            <>
              <SmallDesktop type="minWidth">
                <FooterRow>
                  <Subeading>{textCopies?.shippingHeading}</Subeading>
                  <Subeading>
                    {noFreeShipping ? textCopies?.shippingCalculated : <>
                    {pricedShip && !freeShipping
                      ? textCopies?.shippingCalculated
                      : textCopies?.freeShipping}</>}
                  </Subeading>
                </FooterRow>
              </SmallDesktop>
              <SmallDesktop type="maxWidth">
                {noFreeShipping ? <Subeading>{textCopies?.shippingCalculated}</Subeading> : <>
                {parseFloat(total) <= freeShippingMin && !freeShipping ? (
                  <ProgressContainer>
                    <ProgressBar
                      width={
                        (parseFloat(total).toFixed(2) / freeShippingMin) * 100
                      }
                    />
                    <ProgressBarSubheading>{`Spend ${process.env
                      .GATSBY_CURRENCY_SYMBOL +
                      (freeShippingMin - parseFloat(total)).toFixed(
                        2
                      )} more and receive free shipping!`}</ProgressBarSubheading>
                  </ProgressContainer>
                ) : (
                  <FooterRow>
                    <Subeading>{textCopies?.shippingHeading}</Subeading>
                    <FreeShippingHeading>
                      {textCopies?.freeShippingUnlocked}
                    </FreeShippingHeading>
                  </FooterRow>
                )}
                </>}
              </SmallDesktop>
              {isUK && discount?.has && (
                <FooterRow id="multibuyDiscount">
                  <Subeading>{locale.cart.multibuyDiscount}</Subeading>
                  <Heading>
                    {discount?.amount !== 0 && (
                      <span>
                        -{process.env.GATSBY_CURRENCY_SYMBOL}
                        {(discount?.amount - neckPainProductPrice).toFixed(2)}
                      </span>
                    )} 
                  </Heading>
                </FooterRow>
              )}
               {!isUK && discount?.amount > 0 && (
                <FooterRow>
                  <Subeading>{locale?.cart?.discount}</Subeading>
                  <Heading>
                    {discount?.amount !== 0 && (
                      <span>
                        -{process.env.GATSBY_CURRENCY_SYMBOL}
                        {(discount?.amount).toFixed(2)}
                      </span>
                    )} 
                  </Heading>
                </FooterRow>
              )}
              <FooterRow>
                <Subeading>{textCopies?.subtotalHeading}</Subeading>
                <Heading>{process.env.GATSBY_CURRENCY_SYMBOL + total}</Heading>
              </FooterRow>
            </>
            <CheckoutButton onClick={beginCheckout}>Checkout</CheckoutButton>
          </Footer>
        </>
      ) : (
        renderEmpty()
      )}
    </Container>
  );
};

export default Cart;
