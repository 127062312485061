import React, { useState, useEffect, useCallback } from 'react';
import Image from 'src/components/Common/ImageFluid';
import PortableText from '@sanity/block-content-to-react';
import Icon from 'src/components/Common/Icon';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { encode, decode } from 'shopify-gid';
import { eventData } from 'src/utils/dataLayer';
import _ from 'lodash';
import {
  Card,
  CardImageContainer,
  CardDetail,
  CaseTitle,
  CasePrice,
  Quantity,
  QuantityButton,
  NumberInput,
  IconWrapper,
  CaseDescription
} from './styles';

import {
  client,
  useAddItemToCart,
  useUpdateItemsFromCart,
  useRemoveItemFromCart,
} from 'src/context/siteContext';

interface CaseCardProps {
  pillow?: any;
  canAdd?: boolean;
  lineItems?: any;
}

const CaseCard = ({ pillow, canAdd, lineItems }: CaseCardProps) => {
  const { addItemToCart } = useAddItemToCart();
  const removeFromCart = useRemoveItemFromCart();
  const updateItemsFromCart = useUpdateItemsFromCart();
  // const lineItems = useCartItems();
  const breakpoints = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const [pillowcaseQuantity, setPillowcaseQuantity] = useState<number>(0);
  const productId = pillow?.content?.shopify?.productId;
  const [variant, setVariant] = useState<any | undefined>();
  const [currentItem, setCurrentItem] = useState<any | undefined>();
  const [productTitle, setProductTitle] = useState("" as string);

  useEffect(() => {
    const shopifyId = encode('Product', productId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
    });
    client.product.fetch(shopifyId).then(async (product: any) => {
      setProductTitle(product?.title);
      const decodedVariants = [] as any;
      product?.variants?.forEach((variant: any) => {
        decodedVariants.push({
          ...variant,
          cleanId: parseInt((variant.id).id, 0),
        });
      });
      const availableVariant = decodedVariants?.find((variant: any) => variant);
      if (availableVariant?.available) {
        setVariant(availableVariant);
      }
    });
  }, []);

  useEffect(() => {
    if (variant && lineItems.length) {
      const findCurrent = lineItems.find(
        (item: any) => item?.variant?.id === variant?.id
      );
      setCurrentItem(findCurrent);
    }
  }, [lineItems]);

  useEffect(() => {
    if (currentItem) {
      setPillowcaseQuantity(currentItem?.quantity);
    } else {
      setIsOpen(false);
    }
  }, [currentItem]);

  const debouncedChange = useCallback(
    _.debounce((qty, id) => {
      updateItemsFromCart({ id, quantity: qty });
    }, 150),
    []
  );

  const handlePillow = async () => {

    setPillowcaseQuantity(1);
    dataLayer?.push({
      ...eventData('add_to_cart'),
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
        add: {
          products: [
            {
              name: productTitle,
              id: variant?.sku,
              price: parseFloat(variant?.priceV2?.amount),
              brand: 'GroovePillows',
              variant: variant?.title,
              quantity: 1,
            },
          ],
        },
      }
    });
    await addItemToCart(variant?.id, 1, [], false);
  };

  const addPillowcaseQuantity = () => {
    const selectedItemInCart = lineItems.find(
      (item: any) => item?.variant?.id === variant?.id
    );
    setPillowcaseQuantity(selectedItemInCart.quantity + 1);
    debouncedChange(selectedItemInCart.quantity + 1, selectedItemInCart?.id);
  };

  const removePillowcaseQuantity = () => {
    if (pillowcaseQuantity > 1) {
      setPillowcaseQuantity(pillowcaseQuantity - 1);
      const selectedItemInCart = lineItems.find(
        (item: any) => item?.variant?.id === variant?.id
      );
      setPillowcaseQuantity(selectedItemInCart.quantity - 1);
      debouncedChange(selectedItemInCart.quantity - 1, selectedItemInCart?.id);
    } else {
      debouncedChange.cancel();
      const selectedItemInCart = lineItems.find(
        (item: any) => item?.variant?.id === variant?.id
      );
      dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          currencyCode: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
          items: [
            {
              item_id: variant?.sku,
              item_name: productTitle,
              currency: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
              item_brand: "GroovePillows",
              item_variant: variant?.title,
              price: parseFloat(variant?.price),
              quantity: 1
            }
          ]
        }
      });
      removeFromCart(selectedItemInCart?.id);
      setIsOpen(false);
    }
  };

  return (
    <Card increaseHeight={pillow?.saveMoneyAddText}>
      <CardImageContainer>
        <Image
          imageRef={pillow?.content?.main?.caseImage}
          width={!breakpoints.tablet ? 47 : 56}
          height={!breakpoints.tablet ? 47 : 56}
        />
      </CardImageContainer>
      <CardDetail>
        <CaseTitle>
          <PortableText blocks={pillow?.content?.main?.caseTitle} /></CaseTitle>
        <CasePrice>
          {process.env.GATSBY_CURRENCY_SYMBOL}
          {pillow?.content?.shopify?.defaultVariant?.price}
        </CasePrice>
        {pillow?.content?.main?.productDescription && (
          <CaseDescription>
          <PortableText blocks={pillow?.content?.main?.caseDescription} />
          </CaseDescription>
        )}
      </CardDetail>
      {isOpen ? (
        <Quantity>
          <QuantityButton type="button" onClick={removePillowcaseQuantity}>
            <Icon name="minus" />
          </QuantityButton>
          <NumberInput
            type="number"
            name="quantity"
            readOnly={true}
            defaultValue={1}
            value={pillowcaseQuantity}
            min="1"
            max={pillow?.content?.shopify?.defaultVariant?.inventoryQuantity}
          />
          <QuantityButton type="button" onClick={addPillowcaseQuantity}>
            <Icon name="plus" />
          </QuantityButton>
        </Quantity>
      ) : (
        <IconWrapper
          onClick={() => {
            if (canAdd) {
              setIsOpen(true);
              handlePillow();
            }
          }}
        >
          <Icon name="pink-plus" />
        </IconWrapper>
      )}
    </Card>
  );
};

export default CaseCard;