import React, { FC, ReactElement } from "react";
import { useMediaQuery } from "react-responsive";

interface Props {
  children: ReactElement<any, any> | null;
  type: "minWidth" | "maxWidth";
}

export const size = {
  phone: 480,
  tablet: 768,
  smallDesktop: 992,
  largeDesktop: 1200,
};

export const LargeDesktop: FC<Props> = ({ children, type }: Props) => {
  const isDesktop = useMediaQuery({ [type]: size.largeDesktop });
  return isDesktop ? children : null;
};

export const SmallDesktop: FC<Props> = ({ children, type }: Props) => {
  const isDesktop = useMediaQuery({ [type]: size.smallDesktop });
  return isDesktop ? children : null;
};

export const Tablet: FC<Props> = ({ children, type }: Props) => {
  const isTablet = useMediaQuery({ [type]: size.tablet });
  return isTablet ? children : null;
};

export const SmallTablet: FC<Props> = ({ children, type }: Props) => {
  const isSmallTablet = useMediaQuery({ [type]: size.phone });
  return isSmallTablet ? children : null;
};

export const Mobile: FC<Props> = ({ children, type }: Props) => {
  const isMobile = useMediaQuery({ [type]: size.phone });
  return isMobile ? children : null;
};
