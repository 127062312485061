import styled from "styled-components";
import Slider from "react-slick";
import Icon from "src/components/Common/Icon";

export const Container = styled.div`
  position: relative;
`;

export const StyledSlider = styled(Slider)`
  padding-right: 2rem;
  /* the slides */
  .slick-slide {
    padding-right: 3rem;
  }
  /* the parent */
  /* .slick-list {
    margin: 0 -3rem;
  } */
`;

export const Arrow = styled(Icon)`
  position: absolute;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @media ${({ theme }) => theme.device.tablet} {
    width: 3.2rem;
    height: 3.2rem;
  }
`;
