import styled from "styled-components";

export const Heading = styled.h1`
  white-space: pre-line;
  color: ${({ theme, color }) => color ? theme.colors[color as string] : theme.colors.font_white};
  font-size: 4rem;
  line-height: 4.8rem;
  font-family: 'Effra-Regular';
  font-weight: 400;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 8rem;
    line-height: 8.8rem;
  }
`;

export const SectionHeading = styled.h2`
  white-space: pre-line;
  color: ${({ theme, color }) => theme.colors[color as string]};
  font-size: ${({ theme }) => theme.fontSize["4xl"].mobile};
  line-height: ${({ theme }) => theme.lineHeight["4xl"].mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize["4xl"].desktop};
    line-height: ${({ theme }) => theme.lineHeight["4xl"].desktop};
  }
`;

export const Paragraph = styled.p`
  white-space: pre-line;
  color: ${({ theme, color }) => theme.colors[color as string]};
  font-size: ${({ theme }) => theme.fontSize.base.mobile};
  line-height: ${({ theme }) => theme.lineHeight.base.mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;

  text-decoration: ${({ textStyle }) => textStyle};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize.base.desktop};
    line-height: ${({ theme }) => theme.lineHeight.base.desktop};
  }
`;
