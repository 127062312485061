import React from "react";
import { Block, Heading, StyledButton,  
  WhatsappContainer, WhatsappBtn, BlockAndWhatsapp } from "./styles";
import Whatsapp from "src/images/whatsapp.png";

interface Props {
  className?: string;
  heading?: string;
  button?: {
    enabled: boolean;
    link: string;
    text: string;
  };
  closeReviews: any;
  whatsapp?: object;
}

const CtaBlock = ({ heading, button, className, closeReviews, whatsapp }: Props) => {
  return (
    <Block className={className}>
      {heading && <Heading>{heading}</Heading>}
      <BlockAndWhatsapp>
      {button?.enabled && (
        <StyledButton to={button.link} buttonType="default" onClick={closeReviews} enabled={whatsapp?.enabled}>
          {button.text}
        </StyledButton>
      )}
      {
          whatsapp?.enabled && (
            <WhatsappContainer>
              <WhatsappBtn>
                <a
                  className=""
                  href={`https://api.whatsapp.com/send/?phone=${whatsapp.number}&text=${encodeURI(whatsapp.message)}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Whatsapp} alt="whatsapp" />
                </a>
              </WhatsappBtn>

            </WhatsappContainer>

          )
        }
        </BlockAndWhatsapp>
    </Block>
  );
};

export default CtaBlock;
