import styled, { keyframes } from "styled-components";
import { SectionHeading } from "../Text";
import Icon from "src/components/Common/Icon";

interface DrawerProps {
  open: boolean;
  drawerType: "search" | "reviews" | "cart" | undefined;
}

interface BackdropProps {
  open: boolean;
}

export const Drawer = styled.div<DrawerProps>`
  padding: 2rem;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.pale_blue};
  position: fixed;
  top: 0;
  width: 87.5vw;
  right: 0;
  z-index: 200;
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); */
  /* box-shadow: ${({ open }) =>
    open ? "1px 0px 7px rgba(0, 0, 0, 0.5)" : "none"}; */
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  /* transition: transform 0.3s ease-out; */
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  overflow-y: scroll;
  overflow-x: hidden;

  @media ${({ theme }) => theme.device.tablet} {
    width: 69.4vw;
    padding: 5rem;
  }
`;

const changeBg = keyframes`
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(45, 72, 101, 0.5);
  }
`;

export const Backdrop = styled.div<BackdropProps>`
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  /* padding-left: 9.4rem; */
  padding-left: 5.5vw;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 72, 101, 0.5);
  /* background-color: ${({ open }) =>
    open ? "rgba(45, 72, 101, 0.5)" : "transparent"}; */
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: visibility, opacity;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? "1" : "0")};

  /* background-color: rgba(45, 72, 101, 0.5); */
  /* animation: ${changeBg} 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards; */
  z-index: 100;
  top: 0;
  right: 0;
  /* transition: opacity var(--trans-smooth); */
`;

export const StyledIcon = styled(Icon)``;

export const BackdropTitle = styled(SectionHeading)`
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: ${({ theme }) => theme.colors.font_white};
  display: none;
  align-items: center;

  ${StyledIcon} {
    margin-right: 1.2rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
  }
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  right: 2rem;
  top: 1.7rem;
  width: 1.7rem;
  height: 1.7rem;

  @media ${({ theme }) => theme.device.tablet} {
    z-index: 2;
    right: 5.1rem;
    top: 5.1rem;
    width: 2.25rem;
    height: 2.25rem;
  }
`;
