import React from 'react';
import { EmptyContainer, EmptyHeading, EmptySubheading } from './styles';
interface Props {
    returnMoneyBack?: Object[];
}

const ReturnMoneyBack = ({ returnMoneyBack }: Props) => {
    return (
        <EmptyContainer>
            <EmptyHeading blocks={returnMoneyBack}/>
        </EmptyContainer>
    );
}

export default ReturnMoneyBack;
