import styled from "styled-components";

interface ContProps {
  small: boolean;
}

export const LogoContainer = styled.img<ContProps>`
  width: 7.3rem;
  max-width: ${({ small }) => (small ? "7.3rem" : "11rem")};
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  height: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: ${({ small }) => (small ? "7.8rem" : "14.1rem")};
    height: 100%;
  }
`;
