import styled from 'styled-components';

export const AdditionalInformationContainer = styled.div`
  padding: 12.5rem 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 4.5rem 0 0;
  }
`;

export const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;

  img {
    object-fit: contain !important;
  }
`;
