import React from "react";
import { Container, ItemCount, ShippingText, ProgressBar } from "./styles";

interface Props {
  minPrice: number;
  total: string;
  items: number;
  freeShipping: boolean
}

const ShippingIndicator = ({ minPrice, total, items, freeShipping }: Props) => {
  const pricedShip = parseFloat(total) <= minPrice;
  const shippingCalculation = () => {
    if (pricedShip && !freeShipping) {
      return `Spend ${process.env.GATSBY_CURRENCY_SYMBOL +
        (minPrice - parseFloat(total)).toFixed(
          2
        )} more and receive free shipping!`;
    } else {
      return "You qualify for free shipping!";
    }
  };

  return items > 0 ? (
    <Container>
      {pricedShip && (
        <ProgressBar width={(parseFloat(total).toFixed(2) / minPrice) * 100} />
      )}
      <ItemCount>{items === 1 ? "1 item in your basket" : `${items} items in your basket`}</ItemCount>
      <ShippingText>{shippingCalculation()}</ShippingText>
    </Container>
  ) : (
    <Container>
      <ItemCount>0 item</ItemCount>
      <ShippingText>
        Free Shipping over {process.env.GATSBY_CURRENCY_SYMBOL + minPrice}
      </ShippingText>
    </Container>
  );
};

export default ShippingIndicator;
