import React, { useState, useEffect, useContext } from "react";
import { Checkout } from "shopify-storefront-api-typings";
import ShopifyClient from "shopify-buy";
import cookie from "js-cookie";
import { Product } from "src/interfaces/sanity";
import { eventData } from "src/utils/dataLayer";
import gaEvent from "src/utils/gaEvent";

const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify_checkout_id";

// @ts-ignore
const client = ShopifyClient.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STORE, // TODO: Change .env variable to more understandable name
});

interface ExtendedCheckout extends Checkout {
  id: string;
  discount: {
    code: string;
    percentage: number;
    applicable: boolean;
  };
  discountedAmount: number;
  hasDiscounts: boolean;
  total: string;
}

interface InitialStore {
  shopifyClient: ShopifyClient;
  isAdding: boolean;
  cartIsOpen: boolean;
  drawerOpen: boolean;
  drawerType:
  | "search"
  | "reviews"
  | "cart"
  | "additionalInformation"
  | undefined;
  reviewsType: "questions" | undefined;
  productInfo: {};
  navIsOpen: boolean;
  page: undefined;
  orders: any[];
  customerEmail: string | undefined;
  customerName: string | undefined;
  customerToken: string | undefined;
  checkout: ExtendedCheckout,
  mobileMenuOpen: boolean;
  pillowcaseId: string;
  upsells: {
    open: boolean;
    boughtItem: {
      title: string;
      quantity: any;
      price: string;
      imageRef: string;
    } | null;
    productList: Array<{
      _type: "upsellProduct";
      _key: string;
      price: number;
      product: Product;
      type: "add" | "upgrade";
    }>;
  };
}

const initialStoreState = {
  shopifyClient: client,
  isAdding: false,
  cartIsOpen: false,
  drawerOpen: false,
  drawerType: undefined,
  reviewsType: undefined,
  productInfo: {},
  page: undefined,
  customerEmail: undefined,
  customerName: undefined,
  customerToken: undefined,
  orders: [],
  navIsOpen: false,
  pillowcaseId: null,
  checkout: {
    lineItems: [],
    discount: {
      code: "",
      percentage: 0,
      applicable: false,
    },
    discountedAmount: 0,
    hasDiscounts: false,
  },
  mobileMenuOpen: false,
  upsells: {
    open: false,
    boughtItem: null,
    productList: [],
  },
};

export const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null,
});

const createNewCheckout = (store: InitialStore): Checkout => {
  return store.shopifyClient.checkout.create();
};

const fetchCheckout = (store: InitialStore, id: string): Checkout => {
  return store.shopifyClient.checkout.fetch(id);
};

const setCheckoutInState = (checkout: Checkout, setStore: any) => {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id);
  }

  setStore((prevState: InitialStore) => {
    return { ...prevState, checkout };
  });
};

const initCustomer = (setStore: any) => {
  const customerEmail = cookie.get("customer_email");
  const customerToken = cookie.get("customer_token");
  const customerName = cookie.get("customer_firstName");

  if (customerEmail && customerToken && customerName) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, customerEmail, customerToken, customerName };
    });
  }
};

const StoreContextProvider = ({ children }: { children: any }) => {
  const [store, setStore] = useState(initialStoreState);
  const [initStore, setInitStore] = useState(false);

  useEffect(() => {
    if (initStore === false) {
      const initializeCheckout = async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== "undefined";
        const existingCheckoutId = isBrowser
          ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
          : null;

        if (existingCheckoutId) {
          try {
            const checkout = await fetchCheckout(store, existingCheckoutId);

            // Make sure none of the items in this cart have been deleted from Shopify.
            if (checkout.lineItems.some((lineItem) => !lineItem.variant)) {
              throw new Error(
                "Invalid line item in checkout. This variant was probably deleted from Shopify"
              );
            }

            // Make sure this cart hasn’t already been purchased.
            if (!checkout.completedAt) {
              setCheckoutInState(checkout, setStore);
              return;
            }
          } catch (e) {
            localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null);
          }
        }

        const newCheckout = await createNewCheckout(store);
        setCheckoutInState(newCheckout, setStore);
      };
      initCustomer(setStore);
      initializeCheckout();
      setInitStore(true);
    }
  }, [store, setStore, store.shopifyClient.checkout, initStore]);

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStore() {
  const { store } = useContext(StoreContext);
  return store;
}

function useCartCount() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  let count = 0;
  if (checkout.lineItems) {
    count = checkout.lineItems.reduce(
      (runningTotal: number, item: any) => item.quantity + runningTotal,
      0
    );
  }

  return count;
}

const setCustomerInState = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  async function updateCustomerInState() {
    const customerEmail = cookie.get("customer_email");
    const customerToken = cookie.get("customer_token");
    const customerName = cookie.get("customer_firstName");
    setStore((prevState: InitialStore) => {
      return { ...prevState, customerEmail, customerToken, customerName };
    });
  }

  return updateCustomerInState;
};

function useDiscount() {
  // @ts-ignore
  const {
    store: { checkout, shopifyClient },
    setStore,
  }: { store: InitialStore; setStore: any } = useContext(StoreContext);

  async function removeDiscount() {
    const newCheckout = await shopifyClient.checkout.removeDiscount(
      checkout.id
    );
    // @ts-ignore
    setStore((prevState: any) => {
      return {
        ...prevState,
        checkout: newCheckout,
      };
    });
  }

  async function addDiscount(discountCode: string) {
    const newCheckout = await shopifyClient.checkout.addDiscount(
      checkout.id,
      discountCode
    );
    // @ts-ignore
    setStore((prevState: any) => {
      return {
        ...prevState,
        checkout: newCheckout,
      };
    });
  }

  return { addDiscount, removeDiscount };
}


function useCheckoutId() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return checkout?.id;
}

function useCart() {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  if (checkout?.id) {
    const { lineItems, totalPriceV2, subtotalPriceV2 } = checkout;
    const total = totalPriceV2
      ? `${Number(subtotalPriceV2.amount).toFixed(2)}`
      : "-";

    const discountApplications = checkout?.discountApplications;
    const discountApplicationsLength = discountApplications.length;
    const hasDiscounts = discountApplicationsLength !== 0;
    const hasAutomaticDiscounts =
      hasDiscounts &&
      discountApplications[0]?.type.name === "ScriptDiscountApplication";
    const hasOnlyAutomatic =
      hasAutomaticDiscounts && discountApplicationsLength === 1;
    const discount = hasDiscounts
      ? hasAutomaticDiscounts
        ? discountApplications[1]
        : discountApplications[0]
      : null;

    let discountedAmount = 0;

    lineItems?.forEach((item) => {
      const itemHasDiscounts = item.discountAllocations.length !== 0;
      let firstDiscount = null;
      let itemDiscountAmount;
      if (hasDiscounts && itemHasDiscounts) {
        firstDiscount = item?.discountAllocations[0];
        itemDiscountAmount = firstDiscount?.allocatedAmount?.amount;
        if (itemDiscountAmount)
          discountedAmount += parseFloat(itemDiscountAmount);
      }
    });

    return {
      checkout: {
        ...checkout,
        discount: {
          code: discount?.code,
          percentage: discount?.value?.percentage,
          applicable: discountedAmount > 0,
          amount: parseFloat(discountedAmount.toFixed(2)),
          has: hasOnlyAutomatic ? false : hasDiscounts,
        },
        freeShipping: discount?.targetType === "SHIPPING_LINE",
        total
      },
    };
  }

  return {
    checkout
  }
}

function useCustomer() {
  const {
    store: { customerEmail, customerName, customerToken },
  } = useContext(StoreContext);

  return { customerEmail, customerName, customerToken };
}

function useAddItemToCart() {
  // @ts-ignore
  const {
    store: { checkout, shopifyClient },
    setStore,
  }: { store: InitialStore; setStore: any } = useContext(StoreContext);
  const { addDiscount } = useDiscount();

  const freeGiftVariantId = "gid://shopify/ProductVariant/32876393955414";
  const freeEcourseVariantId = "gid://shopify/ProductVariant/40074074456150";
  const freeGiftVariantIdUS = "gid://shopify/ProductVariant/42756017750166";

  const freeGiftVariantIds = [freeEcourseVariantId, freeGiftVariantIdUS];

  async function addItemToCart(
    variantId: string,
    quantity: number,
    attributes?: [],
    skipCart?: boolean
  ) {
    if (variantId === "" || !quantity) {
      console.error("Both a size and quantity are required.");
      return;
    }

    setStore((prevState: InitialStore) => {
      return { ...prevState, isAdding: true };
    });

    const checkoutId = checkout.id;
    let lineItemsToAdd = [
      { variantId, quantity, customAttributes: attributes },
    ];
    // if (checkout?.lineItems.findIndex(item => item?.variant?.id === freeGiftVariantId) === -1
    //   && (process.env.GATSBY_CURRENCY_SYMBOL !== "$")) {
    //     lineItemsToAdd = [
    //       ...lineItemsToAdd, 
    //       ...freeGiftVariantIds.map(variantId => ({ variantId, quantity: 1 }))
    //     ]
    // }

    const productVariantId = (process.env.GATSBY_CURRENCY_SYMBOL === "$")
      ? freeGiftVariantIdUS
      : freeEcourseVariantId;

    const addGift = process.env.GATSBY_CURRENCY_SYMBOL !== "$" || window.freeGift;

    const lineItemIndex = checkout?.lineItems.findIndex(item =>
      item?.variant?.id === productVariantId
    );

    // if (lineItemIndex === -1 && addGift) {
    //   lineItemsToAdd.push({
    //     variantId: productVariantId,
    //     quantity: 1,
    //   });
    // }


    const newCheckout = await shopifyClient.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    );

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        checkout: newCheckout,
        drawerOpen: skipCart ? false : true,
        drawerType: skipCart ? undefined : "cart",
        isAdding: false,
      };
    });

    const discountCode = window.localStorage.getItem("applyDiscount");
    if (discountCode) await addDiscount(discountCode);
  }

  async function addItemsToCart(list: any) {
    list.map((item) => {
      if (item.variantId === "" || !item.quantity) {
        console.error("Both a size and quantity are required.");
        return;
      }
    });

    setStore((prevState: InitialStore) => {
      return { ...prevState, isAdding: true };
    });

    const checkoutId = checkout.id;
    const lineItemsToAdd = list.map((item) => {
      return {
        variantId: item.variantId,
        quantity: item.quantity,
        customAttributes: item.attributes,
      };
    });

    const newCheckout = await shopifyClient.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    );

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        checkout: newCheckout,
        drawerOpen: true,
        drawerType: "cart",
        isAdding: false,
      };
    });
  }

  return { addItemToCart, addItemsToCart };
}

function addToCartRedirectToCheckout() {
  const {
    store: { checkout, shopifyClient },
    setStore,
  }: { store: InitialStore; setStore: any } = useContext(StoreContext);

  async function addItemToCart(
    variantId: string,
    quantity: number,
    attributes?: [],
    skipCart?: boolean
  ) {
    if (variantId === "" || !quantity) {
      console.error("Both a size and quantity are required.");
      return;
    }

    setStore((prevState: InitialStore) => {
      return { ...prevState, isAdding: true };
    });

    const checkoutId = checkout.id;
    const lineItemsToAdd = [
      { variantId, quantity, customAttributes: attributes },
    ];

    const newCheckout = await shopifyClient.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    );

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        checkout: newCheckout,
        drawerOpen: skipCart ? false : true,
        drawerType: skipCart ? undefined : "test",
        isAdding: false,
      };
    });
  }

  async function addItemsToCart(list: any) {
    list.map((item) => {
      if (item.variantId === "" || !item.quantity) {
        console.error("Both a size and quantity are required.");
        return;
      }
    });

    setStore((prevState: InitialStore) => {
      return { ...prevState, isAdding: true };
    });

    const checkoutId = checkout.id;
    const lineItemsToAdd = list.map((item) => {
      return {
        variantId: item.variantId,
        quantity: item.quantity,
        customAttributes: item.attributes,
      };
    });

    const newCheckout = await shopifyClient.checkout.addLineItems(
      checkoutId,
      lineItemsToAdd
    );

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        checkout: newCheckout,
        drawerOpen: false,
        drawerType: false,
        isAdding: false,
      };
    });
  }

  return () => {
    function redirectCheckoutAfterItems() {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (
            typeof window.ga === "undefined" ||
            window.ga === null ||
            typeof window.ga.getAll === "undefined"
          ) {
            window.open(checkout.webUrl, "_self");
            return;
          }

          const linkerParam = window.ga.getAll()[0].get("linkerParam");
          window.open(`${checkout.webUrl}&${linkerParam}`, "_self");
        }, 1000);
      });
    }

    async function redirectToCheckout() {
      const result = await redirectCheckoutAfterItems();
    }

    redirectToCheckout();
    if (window.google_tag_manager) {
      window.dataLayer = window.dataLayer || [];
      dataLayer?.push({
        ...eventData("dl_begin_checkout"),
        eventTimeout: 2000,
        cart_total: checkout.totalPriceV2?.amount,
        ecommerce: {
          currencyCode:
            process.env.GATSBY_CURRENCY_SYMBOL == "$" ? "USD" : "GBP",
          checkout: {
            products: [],
          },
        },
        eventCallback: redirectToCheckout,
      });
    } else {
      redirectToCheckout();
    }
  };
}

function useRemoveItemFromCart() {
  const {
    store: { checkout, shopifyClient },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function removeItemFromCart(itemId) {
    const newCheckout = await shopifyClient.checkout.removeLineItems(
      checkout.id,
      [itemId]
    );

    setStore((prevState: InitialStore) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return removeItemFromCart;
}

function useUpdateItemsFromCart() {
  const {
    store: { checkout, shopifyClient },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function updateItemsFromCart(items: any) {
    items = [].concat(items);
    const newCheckout = await shopifyClient.checkout.updateLineItems(
      checkout.id,
      items
    );

    setStore((prevState: InitialStore) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return updateItemsFromCart;
}

function useCheckout() {
  const {
    store: { checkout },
  }: {
    store: InitialStore;
  } = useContext(StoreContext);

  return () => {
    const redirectToCheckout = () => {
      if (
        typeof window.ga === "undefined" ||
        window.ga === null ||
        typeof window.ga.getAll === "undefined"
      ) {
        window.open(checkout.webUrl, "_self");
        return;
      }

      const linkerParam = window.ga.getAll()[0].get("linkerParam");
      let webUrl = checkout.webUrl;
      webUrl = webUrl.replace(new URL(checkout.webUrl).hostname, process.env.GATSBY_CHECKOUT_DOMAIN);
      window.location.href = `${webUrl}${linkerParam ? `&${linkerParam}` : ""}`;
    };

    if (window.google_tag_manager) {
      window.dataLayer = window.dataLayer || [];
      dataLayer?.push({
        ...eventData("dl_begin_checkout"),
        eventTimeout: 2000,
        cart_total: checkout.totalPriceV2?.amount,
        ecommerce: {
          currencyCode:
            process.env.GATSBY_CURRENCY_SYMBOL == "$" ? "USD" : "GBP",
          checkout: {
            products: [],
          },
        },
        eventCallback: redirectToCheckout,
      });
    } else {
      redirectToCheckout();
    }
  };
}

function useSetPage() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);
  async function setPage(page: string) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, page };
    });
  }
  return setPage;
}

function useToggleCart() {
  const {
    store: { cartIsOpen },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function toggleCart() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, cartIsOpen: !cartIsOpen };
    });
  }

  return toggleCart;
}

function useDrawer() {
  const {
    store: { drawerOpen },
    setStore,
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function toggleDrawer() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, drawerOpen: !drawerOpen };
    });
  }

  return toggleDrawer;
}

function closeDrawer() {
  const {
    setStore,
  }: {
    setStore: any;
  } = useContext(StoreContext);

  return async function toggleDrawer() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, drawerOpen: false, productInfo: {} };
    });
  };
}

const useReviewDrawer = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  const openReviews = async (product: any) => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        drawerOpen: true,
        drawerType: "reviews",
        productInfo: product,
        reviewsType: product.type,
      };
    });
  };

  const closeReviews = async () => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        drawerOpen: false,
        drawerType: undefined,
        reviewsType: undefined,
        productInfo: {},
      };
    });
  };

  return { openReviews, closeReviews };
};

const useReturnMoneyDrawer = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  const openReturnMoney = async (product: any) => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        drawerOpen: true,
        drawerType: "returnMoney",
      };
    });
  };

  const closeReturnMoney = async () => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        drawerOpen: false,
        drawerType: undefined,
        productInfo: {},
      };
    });
  };

  return { openReturnMoney, closeReturnMoney };
};

const useSearchDrawer = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  const openSearch = async () => {
    setStore((prevState: InitialStore) => {
      return { ...prevState, drawerOpen: true, drawerType: "search" };
    });
  };

  const closeSearch = async () => {
    setStore((prevState: InitialStore) => {
      return { ...prevState, drawerOpen: false, drawerType: undefined };
    });
  };

  return { openSearch, closeSearch };
};

const useCartDrawer = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  const openCart = async () => {
    setStore((prevState: InitialStore) => {
      return { ...prevState, drawerOpen: true, drawerType: "cart" };
    });
  };

  const closeCart = async () => {
    setStore((prevState: InitialStore) => {
      return { ...prevState, drawerOpen: false, drawerType: undefined };
    });
  };

  return { openCart, closeCart };
};

const useAdditionalInformationDrawer = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  const openAdditionalInformation = async () => {
    gaEvent("See medical evidence", "Click", "Medical");

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        drawerOpen: true,
        drawerType: "additionalInformation",
      };
    });
  };

  const closeAdditionalInformation = async () => {
    setStore((prevState: InitialStore) => {
      return { ...prevState, drawerOpen: false, drawerType: undefined };
    });
  };

  return { openAdditionalInformation, closeAdditionalInformation };
};

const usePillowcaseMaterialDrawer = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  const openPillowcaseMaterial = async (id: string) => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        drawerOpen: true,
        drawerType: "pillowcaseMaterial",
        pillowcaseId: id,
      };
    });
  };

  const closePillowcaseMaterial = async () => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        drawerOpen: false,
        drawerType: undefined,
        pillowcaseId: null,
      };
    });
  };

  return { openPillowcaseMaterial, closePillowcaseMaterial };
};

const useMobileMenu = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  const openMenu = async () => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        mobileMenuOpen: true,
      };
    });
  };

  const closeMenu = async () => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        mobileMenuOpen: false,
      };
    });
  };

  return { openMenu, closeMenu };
};

const useUpsells = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  const openUpsells = async ({
    boughtItem,
    productList,
  }: {
    boughtItem: {
      id: string;
      title: string;
      quantity: any;
      price: string;
      imageRef: string;
    };
    productList: Array<{
      _type: "upsellProduct";
      _key: string;
      price: number;
      product: Product;
      type: "add" | "upgrade";
    }>;
  }) => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        upsells: {
          open: true,
          boughtItem,
          productList,
        },
      };
    });
  };

  const closeUpsells = async () => {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        upsells: {
          open: false,
          boughtItem: null,
          productList: [],
        },
      };
    });
  };

  return { openUpsells, closeUpsells };
};

export {
  client,
  StoreContextProvider,
  setCustomerInState,
  useAddItemToCart,
  useCheckoutId,
  useDiscount,
  useStore,
  useCustomer,
  useCartCount,
  useCart,
  useSetPage,
  useRemoveItemFromCart,
  useUpdateItemsFromCart,
  useCheckout,
  useToggleCart,
  useDrawer,
  useReviewDrawer,
  useReturnMoneyDrawer,
  useSearchDrawer,
  useCartDrawer,
  useAdditionalInformationDrawer,
  usePillowcaseMaterialDrawer,
  useMobileMenu,
  useUpsells,
  addToCartRedirectToCheckout,
};
