import styled from "styled-components";
import { SectionHeading } from "../Text";
import CtaBlock from "../CtaBlock";
import ArrowLeft from "src/images/arrow-left.svg";
import Link from "../Link";

export const SearchContainer = styled.div`
  padding: 12.5rem 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 9.5rem 0 0;
  }
`;

export const SearchInput = styled.input`
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 100%;
  font-size: 2rem;
  line-height: 3rem;
  padding-bottom: 0.6rem;
  border: 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.light_gray};
  margin-bottom: 6.3rem;
  background-color: transparent;

  &::placeholder {
    color: ${({ theme }) => theme.colors.font_black};
  }

  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.font_black};
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3.6rem;
    padding-bottom: 1.5rem;
    margin-bottom: 8rem;
  }
`;

export const Results = styled.div`
  margin-bottom: 6rem;
`;

export const ResultHeading = styled(SectionHeading)`
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.colors.font_dark_gray};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3.6rem;
    line-height: 4.7rem;
    margin-bottom: 2rem;
  }
`;

export const ResultList = styled.ul``;

export const ListItem = styled.li`
  list-style-type: none;
  position: relative;
  margin: 0 0 0.6rem;
  padding: 0 0 0 3.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 0;
  }

  &:before {
    content: "";
    background-image: url(${ArrowLeft});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 1.6rem;
  line-height: 3rem;
`;

export const StyledCtaBlock = styled(CtaBlock)`
  margin: auto -2rem -2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin: auto 0 0;
  }
`;
