import styled from 'styled-components';

interface IsActiveProps {
  isActive: boolean;
}

export const AccordionWrapper = styled.div`
  border-bottom: 0.691882px solid #c4c4c4;
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  padding: 22px 0;
`;

export const AccordionTitle = styled.h2`
  font-family: 'Effra-Regular';
  font-size: 28px;
  font-weight: 400;
  color: #0f0923;
`;

export const ImageWrapper = styled.div`
  height: 47px;
  width: 47px;
  overflow: hidden;
  border-radius: 9999px;
`;

export const ToggleWrapper = styled.div``;

export const IsActive = styled.img<IsActiveProps>`
  transform: ${(props) => (props.isActive ? 'rotate(180deg)' : '')};
  width: 33px;
  height: 16px;
  transition: 0.2s all ease;
`;

export const AccordionContent = styled.div`
  padding: 20px 10px 40px;
`;

export const ProductDescription = styled.p`
  font-family: 'Effra-Regular';
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #000;
  opacity: 0.8;
`;

// export const ProductTagWrapper = styled.div`
//   display: flex;
//   column-gap: 22px;
// `;

export const ProductTag = styled.span`
  display: block;
  font-family: 'Effra-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000;
  opacity: 0.8;

  &:not(:last-child) {
    position: relative;

    &::after {
      content: '';
      top: 50%;
      right: -11px;
      position: absolute;
      height: 2px;
      width: 2px;
      border-radius: 9999px;
      background-color: #000;
    }
  }
`;
