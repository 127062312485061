import React from "react";
import { Link } from "gatsby";
import { LogoContainer } from "./styles";

interface Props {
  url: string;
  small: boolean;
  link?: string;
}

const Logo = ({ url, small, link }: Props) => {
  return (
    <Link to={link ? link : "/"}>
      <LogoContainer src={url} alt="logo" small={small} />
    </Link>
  );
};

export default Logo;
