import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 70px;
`;

export const DrawerTitle = styled.h2`
  font-family: 'Effra-Regular';
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 60px;
  color: #0f0923;
`;
