import React, { useState, useEffect } from "react";
import {
  Container,
  StyledWrap,
  Text,
  SaleOffer,
  LinkedText,
  TextColor,
  DesktopContainer,
  MobileContainer,
} from "./styles";
import { size } from "../Responsive";
import { Serializer } from "src/utils/serializer"
import { isEmptyObject } from "src/utils/common";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { duration } from "src/utils/helpers";
import { Link } from "gatsby";

interface Props {
  enabled: boolean;
  whiteTextDesktop: any;
  yellowTextDesktop: string;
  whiteTextMobile: any;
  yellowTextMobile: string;
  discountName: string;
  countdownDate: any;
}

const NotificationBar = ({
  enabled,
  whiteTextDesktop,
  yellowTextDesktop,
  whiteTextMobile,
  yellowTextMobile,
  countdownDate,
  discountName,
}: Props) => {
  const breakpoints = useBreakpoint();
  let interval = null;

  const [timeLeft, setTimeLeft] = useState(duration(`${countdownDate}`));

  useEffect(() => {
    interval = setInterval(() => {
      setTimeLeft(duration(`${countdownDate}`));

    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const { days, hours, minutes, seconds } = timeLeft;

  if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
    clearInterval(interval);
  }

  return enabled ? (
    <Container id="notification-bar">
      <StyledWrap>
        <>
          <DesktopContainer>
            <Text blocks={whiteTextDesktop} serializers={Serializer} />
            <TextColor>{yellowTextDesktop}</TextColor>{" "}
            {discountName && <span className="code">{discountName}</span>}
            {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) && (
              <div className="countdown">
                {days > 0 && (
                  <div className="container">
                    <span className="number">
                      {days.toFixed(0).padStart(2, "0")}
                    </span>
                    <span className="text">d</span>
                  </div>
                )}
                <div className="container">
                  <span className="number">
                    {hours.toFixed(0).padStart(2, "0")}
                  </span>
                  <span className="text">h</span>
                </div>
                <div className="container">
                  <span className="number">
                    {minutes.toFixed(0).padStart(2, "0")}
                  </span>
                  <span className="text">m</span>
                </div>
                <div className="container">
                  <span className="number">
                    {seconds.toFixed(0).padStart(2, "0")}
                  </span>
                  <span className="text">s</span>
                </div>
              </div>
            )}
          </DesktopContainer>

          <MobileContainer>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Text blocks={whiteTextMobile} serializers={Serializer} />
              <div className="discount-code">
                <TextColor>{yellowTextMobile}</TextColor>{" "}
                {discountName && <span className="code">{discountName}</span>}
              </div>
              {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) && (
                <div className="countdown">
                  {days > 0 && (
                    <div className="container">
                      <span className="number">
                        {days.toFixed(0).padStart(2, "0")}
                      </span>
                      <span className="text">d</span>
                    </div>
                  )}
                  <div className="container">
                    <span className="number">
                      {hours.toFixed(0).padStart(2, "0")}
                    </span>
                    <span className="text">h</span>
                  </div>
                  <div className="container">
                    <span className="number">
                      {minutes.toFixed(0).padStart(2, "0")}
                    </span>
                    <span className="text">m</span>
                  </div>
                  <div className="container">
                    <span className="number">
                      {seconds.toFixed(0).padStart(2, "0")}
                    </span>
                    <span className="text">s</span>
                  </div>
                </div>
              )}
            </div>
          </MobileContainer>
        </>
      </StyledWrap>
    </Container>
  ) : null;
};

export default NotificationBar;
