import styled, { keyframes } from "styled-components";
import Icon from "../Common/Icon";
import { Props } from "./index";

const rubberBand = keyframes`
  0% {
   -webkit-transform:scaleX(1);
   transform:scaleX(1)
  }
  30% {
   -webkit-transform:scale3d(1.25,.75,1);
   transform:scale3d(1.25,.75,1)
  }
  40% {
   -webkit-transform:scale3d(.75,1.25,1);
   transform:scale3d(.75,1.25,1)
  }
  50% {
   -webkit-transform:scale3d(1.15,.85,1);
   transform:scale3d(1.15,.85,1)
  }
  65% {
   -webkit-transform:scale3d(.95,1.05,1);
   transform:scale3d(.95,1.05,1)
  }
  75% {
   -webkit-transform:scale3d(1.05,.95,1);
   transform:scale3d(1.05,.95,1)
  }
  to {
   -webkit-transform:scaleX(1);
   transform:scaleX(1)
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 2rem;
  cursor: pointer;

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 2.7rem;
  }
`;

export const Container = styled.div<Props>`
  display: flex;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 1.8rem;
    margin-top: ${({ marginTop }) => (marginTop ? "1.8rem" : "0")};
  }

  ${StyledIcon}:last-child {
    margin-right: 0;
  }
`;

export const CartWrap = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 2rem;

  ${StyledIcon} {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.device.laptop} {
    margin-right: 0;

    ${StyledIcon} {
      margin-right: 0;
    }
  }
`;

export const CountBubble = styled.span`
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 100px;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  animation-name: ${rubberBand};
  animation-duration: 1s !important;
  animation-fill-mode: both !important;
  animation-iteration-count: infinite;
`;
