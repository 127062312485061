import React, { useEffect, useRef, useState } from "react";
import Nav from "src/components/Nav";
import Logo from "../../components/Logo";
import Actions from "src/components/Actions";
import useHeaderData from "../../hooks/useHeaderData";
import _ from "lodash";
import { HeaderContainer, StyledWrap, SubMenu, SubMenuItem, SubMenuLink, VerticalLine, AfterTheLineWrap, VerticalLineAfter } from "./styles";
import { isSSR } from "src/utils/ssr";
import { useLocation } from "@reach/router";

interface Props {
  transparent: boolean;
  notificationBar: boolean;
}

const getDimensions = (el) => {
  const { height } = el.getBoundingClientRect();
  const offsetTop = el.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const Header = ({ transparent, notificationBar, data, afterTheLineItems }: Props) => {
  const { headerMenu, logo } = useHeaderData();
  const location = useLocation();
  const headerRef = useRef(null);
  const [solid, setSolid] = useState(false);
  const filteredSubMenu = data?.subMenu?.filter((item: any) => location?.pathname !== item.link).slice(0, 3);

  useEffect(() => {
    if (isSSR || !transparent) return;

    const heroSection = document.getElementById("hero");

    if (!heroSection) return;

    const throttledCount = _.throttle(() => {
      if (!headerRef.current) return;

      const { offsetBottom: heroBottom } = getDimensions(heroSection);
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPos = window.scrollY + headerHeight;

      // if (scrollPos >= heroBottom) {
      if (scrollPos >= headerHeight + 20) {
        setSolid(true);
      } else {
        setSolid(false);
      }
    }, 300);

    window.addEventListener("scroll", throttledCount);

    return () => window.removeEventListener("scroll", throttledCount);
  }, [location]);

  const isUS = process.env.GATSBY_CURRENCY_SYMBOL == "$";

  return (
    <HeaderContainer
      transparent={false}
      ref={headerRef}
      isNotificationBar={notificationBar}
      id="header"
      className={!filteredSubMenu?.length> 0 || ( isUS && location.pathname === "/products/easy-sleeper") ? "headerWithoutSubmenu": "header"}
    >
      <StyledWrap>
        <Logo
          url={logo.image.asset.url}
          small={true}
          link={logo.link}
        />
        <Nav menu={headerMenu.items} marginTop={false} />
        {afterTheLineItems &&
          <AfterTheLineWrap>
            <VerticalLineAfter></VerticalLineAfter>
            <Nav menu={afterTheLineItems} marginTop={false} />
          </AfterTheLineWrap>
        }
        <Actions marginTop={false} />
      </StyledWrap>
      {!filteredSubMenu?.length> 0 || ( isUS && location.pathname === "/products/easy-sleeper") ? "" :
        <SubMenu id="subMenu">
        {filteredSubMenu?.map((item: any, index) => (
        <>
          <VerticalLine></VerticalLine>
              <SubMenuItem key={index}>
                <SubMenuLink key={index} to={item.link} aria-label="View item">
                  {item.title}
                </SubMenuLink>
              </SubMenuItem>
              
            </>
            ))
            }
        </SubMenu>
      }
    </HeaderContainer>
  );
};

export default Header;
