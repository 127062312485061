import React, { useState, useEffect } from "react";
import { SanityImage } from "src/interfaces/sanity";
import { encode, decode } from "shopify-gid";
import Ribbon from "src/components/Ribbon";
import { client, useAddItemToCart } from "src/context/siteContext";
import {
  ContainerLink,
  InfoBox,
  PriceBox,
  Title,
  Price,
  OldPrice,
  StyledImage,
  Link,
} from "./styles";

interface Props {
  id: number;
  title: string;
  slug: string;
  image?: SanityImage;
  ribbon?: string;
  price: string;
  oldPrice?: string;
  mobilePhoto?: SanityImage;
  loadOldImages?: boolean;
  className?: string;
  onClick?: () => void;
}

const UpsellProduct = ({
  id,
  title,
  image,
  ribbon,
  price,
  oldPrice,
  mobilePhoto,
  loadOldImages,
  className,
  onClick,
  slug,
}: Props) => {
  const { addItemToCart } = useAddItemToCart();
  const [available, setAvailable] = useState(false);
  const [variants, setVariants] = useState([]);
  const [check, setCheck] = useState(true);
  const [activeVariantId, setActiveVariantId] = useState("" as string);
  const [compareAtPrice, setCompareAtPrice] = useState(
    undefined as string | undefined
  );
  const [adding, setAdding] = useState<boolean>(false);

  useEffect(() => {
    if (check) {
      const shopifyId = encode("Product", id, {
        accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
      });

      client.product.fetch(shopifyId).then((product: any) => {
        const decodedVariants = [] as any;
        product.variants.forEach((variant: any) => {
          decodedVariants.push({
            ...variant,
            cleanId: parseInt((variant.id).id, 0),
          });
        });

        setVariants(decodedVariants);
        setActiveVariantId(decodedVariants[0].id as string);
        setAvailable(decodedVariants[0].available);

        if (decodedVariants[0].compareAtPrice)
          setCompareAtPrice(decodedVariants[0].compareAtPrice);

        setCheck(false);
      });
    }
  }, [check]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (available) {
      const attributes = [];

      // if (mobilePhoto) {
      //   attributes.push({ key: "mobilePhoto", value: mobilePhoto.asset._ref });
      // }
      setAdding(true);
      addItemToCart(activeVariantId, 1, attributes).then(() => {
        setAdding(false);
      });
    }
  };

  return (
    <ContainerLink
      className={className}
      to={`/products/${slug}`}
      onClick={onClick}
    >
      {image && (
        <StyledImage
          imageRef={mobilePhoto ? mobilePhoto : image}
          loadOld={loadOldImages}
        />
      )}
      {ribbon && <Ribbon small={true}>{ribbon}</Ribbon>}
      <InfoBox>
        <Title>{title}</Title>
        <PriceBox>
          <Price>{process.env.GATSBY_CURRENCY_SYMBOL + price}</Price>
          {oldPrice && <OldPrice>{oldPrice}</OldPrice>}
        </PriceBox>
        <Link onClick={handleClick} disabled={!available}>
          {adding ? "Adding..." : "Add to cart"}
        </Link>
      </InfoBox>
    </ContainerLink>
  );
};

export default UpsellProduct;
