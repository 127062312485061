import styled from "styled-components";
import { SectionHeading } from "src/components/Text";

interface WidthProps {
  width: number;
}

export const Container = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  left: 0;
  top: 1.4rem;
  padding-left: 2rem;
  background-color: ${({ theme }) => theme.colors.pale_blue};
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    height: 4.8rem;
    z-index: 1;
    top: initial;
    padding-left: 5rem;
    transform: translateY(-25%);
  }
`;

const Text = styled(SectionHeading)`
  font-size: 1.5rem;
  line-height: 2.4rem;
`;

export const ItemCount = styled(Text)``;

export const ShippingText = styled(Text)`
  position: absolute;
  display: none;
  left: 50%;
  transform: translateX(-50%);

  @media ${({ theme }) => theme.device.tablet} {
    display: block;
  }
`;

export const ProgressBar = styled.div<WidthProps>`
  background-color: #eaeef5;
  display: none;
  opacity: 0.5;
  height: 4.8rem;
  width: 100%;
  position: absolute;
  left: 0;

  @media ${({ theme }) => theme.device.tablet} {
    display: block;
  }

  &:before {
    content: "";
    position: absolute;
    background-color: #d5dcea;
    width: ${({ width }) => `${width}%`};
    height: 4.8rem;
    left: 0;
  }
`;
