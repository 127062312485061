import styled from "styled-components";
import Image from "../../components/Common/ImageFluid";
import { Paragraph } from "../../components/Text";

interface ButtonProps {
  color?: string;
  backgroundColor?: string;
}

export const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.font_black};
  padding: 2.7rem 0 5rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6rem 0 8rem;
  }
`;

export const FooterHeader = styled.div`
  margin-bottom: 5.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 6rem;
    display: grid;
    grid-template-columns:
      minmax(21.1805vw, 30.5rem) minmax(16.0416vw, 23.1rem) minmax(
        21.1805vw,
        30.5rem
      )
      minmax(13.6805vw, 19.7rem);
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.font_gray};
`;

export const Caption = styled(StyledParagraph)`
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: 3rem;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 23.1rem;
    margin-bottom: 0;
    font-size: 1.8rem;
    margin-right: 2rem;
    box-sizing: content-box;
  }
`;

export const NavHeading = styled(StyledParagraph)`
  margin-bottom: 2.4rem;
  font-size: 1.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.8rem;
  }
`;

export const NavParagraph = styled(StyledParagraph)`
  font-size: 1.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.4rem;
  }
`;

export const StyledInput = styled.input`
  background-color: transparent;
  border: 0;
  flex-grow: 1;
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize.xs.mobile};
  line-height: ${({ theme }) => theme.lineHeight.base.desktop};
  color: ${({ theme }) => theme.colors.font_gray};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.font_dark_gray};
  margin-right: 2rem;
  &::placeholder {
    color: ${({ theme }) => theme.colors.font_dark_gray};
  }

  &:focus {
    outline: 0;
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
`;

export const FormContainer = styled.form`
  position: relative;
  display: flex;
`;

export const SubmitButton = styled.button<ButtonProps>`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: 'Effra-Medium';
  color: ${({ theme, color }) => color ? color : theme.colors.font_black};
  border: 0;
  background-color: ${({ backgroundColor }) =>  backgroundColor ? backgroundColor : 'transparent'};
  cursor: pointer;
  padding: 6px 20px;
  border-radius: 4px;

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    max-width: 142px;
  }

  @media ${({ theme }) => theme.device.phone} {
    padding: 6px 7px;
  }
`;

export const Logo = styled(Image)`
  margin-bottom: 4rem;
  max-width: 9.4rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0;
    max-width: 14.1rem;
  }
`;

export const FooterNav = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 6rem;
  column-gap: 4rem;

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns:
      minmax(21.1805vw, 30.5rem) minmax(16.0416vw, 23.1rem) minmax(
        21.1805vw,
        30.5rem
      )
      minmax(13.6805vw, 19.7rem);
    justify-content: space-between;
  }
`;

export const FooterNavItem = styled.div``;

export const SuccessMessage = styled(Paragraph)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.font_gray};
  font-size: 1.4rem;
  line-height: 3rem;
`;

export const SocialIconsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5%;
  padding-top: 12%;
  row-gap: 30%;
   
  img {
    max-height: 25px;
  }
`;
