import React, { FC } from "react";
import { Container } from "./styles";

interface Props {
  small?: boolean;
  left?: boolean;
}

const Ribbon: FC<Props> = ({ small, children, left }) => {
  return <Container small={small} left={left}>{children}</Container>;
};

export default Ribbon;
