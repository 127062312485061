import React from "react";
import { Container, StyledIcon, CartWrap, CountBubble } from "./styles";
import { SmallDesktop } from "../Responsive";
import { useTheme } from "styled-components";
import {
  useSearchDrawer,
  useCartDrawer,
  useMobileMenu,
  useCartCount,
} from "src/context/siteContext";

export interface Props {
  marginTop: boolean;
}

const Actions = ({ marginTop }: Props) => {
  const { openSearch } = useSearchDrawer();

  const { openCart } = useCartDrawer();
  const { openMenu } = useMobileMenu();
  const count = useCartCount();
  const theme = useTheme();

  return (
    <Container marginTop={marginTop}>
      <SmallDesktop type="minWidth">
        <StyledIcon
          name="search"
          onClick={openSearch}
          color={theme.colors.font_white}
        />
      </SmallDesktop>
      <CartWrap onClick={openCart}>
        <StyledIcon name="shopping-cart" />
        {count > 0 && <CountBubble />}
      </CartWrap>

      <SmallDesktop type="maxWidth">
        <StyledIcon name="burger" onClick={openMenu} />
      </SmallDesktop>
    </Container>
  );
};

export default Actions;
