export function duration(futureDate) {
    const current = new Date();
    const future = new Date(futureDate);

    let delta = (future - current) / 1000;

    if (delta < 0) {
        return {
            days: 0, hours: 0, minutes: 0, seconds: 0,
        };
    }

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = delta % 60;
    return {
        days, hours, minutes, seconds,
    };
}

export const extractIdFromGid = (gid: string) => gid?.match(/[^/]+$/i)[0];