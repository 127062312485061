import { getGatsbyImageData } from "gatsby-source-sanity";

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  useCdn: true
};

const getImagePath = (node, srcSet = false, width = 2400, height = null) => {
  if (node?.asset === undefined) return "https://cdn.sanity.io/images/69kas9zj/production/820c5c7a0b55ada20ed9e76e6aeddc8497d8ef14-1993x1027.jpg?w=1993&h=1027&auto=format";

  const gImgData = getGatsbyImageData(
    node,
    { 
      width : width,
      height : height 
    },
    sanityConfig
  );

  const processSrc = (image) => srcSet ? image.replace(/(\r\n|\n|\r)/gm, "") : image.split(" ")[0];

  if (gImgData.images.sources.length > 0){
    const imageSrc = processSrc(gImgData.images.sources[0].srcSet);
    return imageSrc;
  }

  if (gImgData.images.fallback){
    const imageSrc = processSrc(gImgData.images.fallback.src);
    return imageSrc;
  }
};

export default getImagePath;
