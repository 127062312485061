import styled from "styled-components";
import PageLink from "src/components/Link";

interface ContProps {
  marginTop: boolean;
}

export const StyledLink = styled(PageLink)`
  margin-right: 2rem;
`;

export const NavContainer = styled.nav<ContProps>`
  display: none;
  margin-top: 2rem;
  margin-top: ${({ marginTop }) => (marginTop ? "2rem" : "0")};
  margin-left: auto;
  margin-right: 7rem;

  justify-content: space-between;

  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
  }

  ${StyledLink}:last-child {
    margin-right: 0;
  }
`;
