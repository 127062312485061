import React, { FC } from "react";
import { InternalLink, ExternalLink } from "./styles";

interface Props {
  className?: string;
  color?: string;
  to: string;
  type?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
}

const PageLink: FC<Props> = ({ children, to, ...props }) => {
  let internal = /^\/(?!\/)/.test(to);

  if (to && typeof to === "string" && to.substring(0, 1) === "#") {
    internal = true;
  }

  return internal ? (
    <InternalLink to={to} {...props}>
      {children}
    </InternalLink>
  ) : (
    <ExternalLink href={to} target="__blank" {...props}>
      {children}
    </ExternalLink>
  );
};

export default PageLink;
