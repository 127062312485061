import styled from "styled-components";
import Icon from "../Common/Icon";

export const RatingStar = styled(Icon)`
  margin-right: 0.1rem;
  width: 2.675rem;
  height: 2.675rem;
  @media ${({theme}) => theme.device.smTablet} {
    width: 1.675rem;
    height: 1.675rem;
  }
  @media ${({theme}) => theme.device.laptop} {
    width: 1.675rem;
    height: 1.675rem;
  }
`;

export const RatingsList = styled.div`
  display: flex;

  ${RatingStar}:last-child {
    margin-right: 0;
  }
`;
