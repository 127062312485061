import { Link } from "gatsby";
import styled from "styled-components";

export const InternalLink = styled(Link)`
  color: ${({ theme, color }) => theme.colors[color as string]};
  font-size: ${({ theme }) => theme.fontSize.base.desktop};
  font-family: 'Effra-Regular';
`;

export const ExternalLink = styled.a`
  color: ${({ theme, color }) => theme.colors[color as string]};
  font-size: ${({ theme }) => theme.fontSize.base.desktop};
  font-family: 'Effra-Regular';
`;
