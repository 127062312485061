import React, { FC, useEffect } from "react";
import { StyledImage, StyledSvg } from "./styles";
import { getGatsbyImageData } from "gatsby-source-sanity";

interface Props {
  imageRef: any;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
  loadOld?: boolean;
}

const Image: FC<Props> = ({
  imageRef,
  className,
  alt = "",
  width,
  height,
  loadOld,
  ...props
}) => {
  if (!imageRef) return null;

  const sanityConfig = {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  };

  const imageData = getGatsbyImageData(
    imageRef?.asset?._ref,
    { width },
    sanityConfig
  );


  return imageData?.images?.sources?.length > 0 || loadOld ? (
    <StyledSvg {...imageRef} className={className} alt={alt} {...props} />
  ) : (
    <StyledImage
      image={imageData}
      className={className}
      alt={alt}
    />
  );
};

// export default Image;

export default React.memo(Image);
