import React, { useState } from 'react';
import {
  AccordionContent,
  AccordionHeader,
  ImageWrapper,
  AccordionTitle,
  AccordionWrapper,
  IsActive,
  ToggleWrapper,
  HeaderWrapper,
  ProductDescription,
  // ProductTagWrapper,
  ProductTag,
} from './styles';
import chevronDown from '../../images/chevron-down.svg';
import Image from 'src/components/Common/ImageFluid';
import PortableText from '@sanity/block-content-to-react';

interface Props {
  title: any;
  content: any;
  image: any;
  selectedId: string;
  productId: string;
}

const Accordion = ({ title, image, content, productId, selectedId }: Props) => {
  const [isActive, setIsActive] = useState(productId === selectedId);
  const description = content?.main?.productDescription;
  // const tags = content?.main?.tags;

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={() => setIsActive(!isActive)}>
        <HeaderWrapper>
          <AccordionTitle>{title}</AccordionTitle>
          <ImageWrapper>
            <Image imageRef={image} alt="header" />
          </ImageWrapper>
        </HeaderWrapper>
        <ToggleWrapper>
          <IsActive src={chevronDown} isActive={isActive} />
        </ToggleWrapper>
      </AccordionHeader>
      {isActive && (
        <AccordionContent>
          <ProductDescription>
            {description &&
              description.map((item: any, idx: number) => (
                <PortableText blocks={item} key={idx} />
              ))}
          </ProductDescription>
          {/* <ProductTagWrapper>
            {tags &&
              tags.length > 0 &&
              tags.map((tag: any, idx: number) => (
                <ProductTag key={idx}>{tag.label}</ProductTag>
              ))}
          </ProductTagWrapper> */}
        </AccordionContent>
      )}
    </AccordionWrapper>
  );
};

export default Accordion;
