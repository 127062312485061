import styled, { css } from "styled-components";
import Product from "../ProductItem";
import Slider from "react-slick";
import Icon from "src/components/Common/Icon";
import { Paragraph } from "../Text";
import { ContainerLink } from "../ProductItem/styles";

interface ListProps {
  firstLarge?: boolean;
  mobileGrid?: boolean;
}

export const Heading = styled(Paragraph)`
  font-size: 1.5rem;
  line-height: 1.7rem;
  margin-bottom: 1.4rem;
`;

export const StyledProduct = styled(Product)`
  /* margin-bottom: 2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0;
  } */
  
  
  @media ${({ theme }) => theme.device.laptop} {
    aspect-ratio: unset !important;
  }
`;

export const List = styled.div<ListProps>`
  ${({ mobileGrid }) =>
    mobileGrid
      ? css`
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: 1fr 1fr;
        `
      : css`
          display: static;
        `};

  @media ${({ theme }) => theme.device.tablet} {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  ${({ firstLarge }) =>
    firstLarge &&
    `
${StyledProduct}:first-child {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  `}
`;

export const Container = styled.div`
  position: relative;
  margin-top: 2rem;
`;

export const StyledSlider = styled(Slider)`
  margin-right: -2rem;
  /* padding-right: 2rem; */
  /* the slides */
  .slick-slide {
    padding-right: 2rem;
    /* box-sizing: content-box; */
    /* min-width: 13.5rem; */
    /* padding-right: 2rem; */
    /* width: 42.18vw; */
    /* box-sizing: content-box; */
  }
  /* the parent */
  /* .slick-list {
    margin: 0 -3rem;
  } */
  /* .slick-list {
    padding: 0 20% 0 0;
  } */
`;

export const Arrow = styled(Icon)`
  position: absolute;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  right: 0;
  top: -0.8rem;
`;
