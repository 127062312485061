import React from "react";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react"; // TODO: Create typings (or find them somewhere)

const Content = ({ blocks, className }: any) => {
  const serializers = {
    types: {
      embedHTML: (props: { node: { html: any }}) => {
        return <div dangerouslySetInnerHTML={{ __html: props.node.html }} />;
      }
    },
    marks: {
      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target="_blank" rel="noopener">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
  };

  return (
    <BlockContent
      blocks={blocks}
      className={className}
      renderContainerOnSingleChild={true}
      serializers={serializers}
    />
  );
};

export default Content;
