import styled from "styled-components";
import Wrap from "../../components/Wrap";
import PageLink from "src/components/Link";

interface ContProps {
  transparent: boolean;
  isNotificationBar: boolean;
}

export const StyledWrap = styled(Wrap)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SubMenu = styled.div`
  width: 100%;
  display: flex;
  padding: 2.6rem 2rem 1.7rem 2rem;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.tablet} { 
    display: none;
  }
`;

export const SubMenuItem = styled.div`
  display: contents;
  color: white;
  padding: 0 4px;
  text-align: center;
`;

export const VerticalLine = styled.div`
  height: 15px;
  padding: 0px;
  width: 1px;
  background: white;
  &:first-child {
    display:none
  }
`;

export const SubMenuLink = styled(PageLink)`
  display: flex;
  font-family: 'Effra-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

export const HeaderContainer = styled.header<ContProps>`
  display: flex;
  width: 100%;
  background-color: ${({ theme, transparent }) =>
    transparent ? "transparent" : theme.colors.bg_black};
  height: ${({ transparent }) => (transparent ? "auto" : "10rem")};
  padding-top: ${({ transparent }) => (transparent ? "1.3rem" : "10px")};
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  flex-direction: column;
  justify-content: center;

  ${StyledWrap} {
    align-items: ${({ transparent }) =>
      transparent ? "flex-start" : "center"};
  }

  @media ${({ theme }) => theme.device.tablet} {
    height: ${({ transparent }) => (transparent ? "auto" : "6.9rem")};
    padding-top: ${({ transparent }) => (transparent ? "2.2rem" : "0")};
  }

  &.headerWithoutSubmenu {
    height: 7rem;
    padding: 10px 0;
  }
`;

export const AfterTheLineWrap = styled.div`
  display: none;
`

export const VerticalLineAfter = styled.div`
  @media ${({ theme }) => theme.device.laptop} {
    height: 15px;
    padding: 0px;
    width: 1px;
    background: white;
    margin-right: 15px;
  }
`;