import React, { useRef } from "react";
import {
  List,
  StyledProduct,
  Container,
  StyledSlider,
  Arrow,
  Heading,
} from "./styles";
import { useReviewDrawer } from "src/context/siteContext";
import { Product } from "../../interfaces/sanity";

interface Props {
  products: Product[];
  firstLarge?: boolean;
  mobileGrid?: boolean;
  className?: string;
  heading?: string;
  loadOldImages?: boolean;
}

const ProductList = ({
  products,
  firstLarge = false,
  mobileGrid = false,
  className,
  heading,
  loadOldImages,
}: Props) => {
  const sliderRef = useRef(null);
  const { closeReviews } = useReviewDrawer();
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    arrows: false,
    slidesToScroll: 1,
    // centerPadding: "0 20% 0 0",
    // centerMode: true,
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  return (
    <Container className={className}>
      {heading && <Heading>{heading}</Heading>}
      <Arrow name="arrow-right" onClick={nextSlide} />
      <StyledSlider {...settings} ref={sliderRef}>
        {products.map((product) => {
          return (
            <StyledProduct
              id={product?.content?.shopify?.productId}
              key={product?._id}
              slug={product?.content?.main.slug?.current}
              okendoReviews={product?.content?.main?.okendoReviews}
              title={product?.content?.main.title}
              image={product?.content?.shopify.image}
              price={product?.content?.shopify?.defaultPrice}
              // ribbon="Bestseller"
              ribbonSmall={true}
              ribbon={
                product?.content?.main?.ribbon?.enabled
                  ? product?.content?.main?.ribbon?.text
                  : undefined
              }
              mobileSmall={true}
              addToCart={true}
              description={
                firstLarge && i === 0
                  ? product?.content?.main?.productDescription
                  : undefined
              }
              mobilePhoto={
                product?.content?.main?.mobileGallery?.length > 0 &&
                product?.content?.main?.mobileGallery[0]
              }
              loadOldImages={loadOldImages}
              showPrice={true}
              onClick={closeReviews}
            />
          );
        })}
      </StyledSlider>
    </Container>
  );
};

export default ProductList;
