import React, { useRef } from "react";
import { Product } from "src/interfaces/sanity";
import { StyledSlider, Arrow, Container } from "./styles";
import UpsellProduct from "src/components/UpsellProduct";
import { useReviewDrawer } from "src/context/siteContext";

interface Props {
  products: Product[];
  className?: string;
}

const CartUpsells = ({ products, className }: Props) => {
  const { closeReviews } = useReviewDrawer();
  const sliderRef = useRef(null);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    arrows: false,
    slidesToScroll: 1,
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <Container className={className}>
      <StyledSlider {...settings} ref={sliderRef}>
        {products.map((product) => {
          return (
            <UpsellProduct
              key={product._id}
              id={product?.content?.shopify?.productId}
              slug={product.content.main.slug.current}
              title={product.content.main.title}
              image={product.content.shopify.image}
              price={product.content.shopify.defaultPrice}
              // ribbon="Bestseller"
              ribbon={
                product.content.main.ribbon?.enabled
                  ? product.content.main.ribbon?.text
                  : undefined
              }
              mobilePhoto={
                product.content.main.mobileGallery?.length > 0 &&
                product.content.main.mobileGallery[0]
              }
              loadOldImages={true}
              onClick={closeReviews}
            />
          );
        })}
      </StyledSlider>
      {
        products?.length > 2 && (
          <Arrow name="arrow-right" onClick={nextSlide} />
        )
      }
    </Container>
  );
};

export default CartUpsells;
