import styled from "styled-components";
import CartItem from "../CartItem";

export const Container = styled.div`
  padding-top: 3.3rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 7.8rem;
  }
`;

export const StyledItem = styled(CartItem)`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
