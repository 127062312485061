import { createGlobalStyle } from "styled-components";
import { sliderStyles, sliderTheme } from "./slick";

export const size = {
  smPhone: "340px",
  phone: "480px",
  tablet: "768px",
  smallDesktop: "992px",
  largeDesktop: "1200px",
  phoneLandscapeSize: "420px",
  tabletLandscapeSize: "567px",
};

export const theme = {
  colors: {
    font_white: "#ffffff",
    font_black: "#0F0923",
    font_pink: "#E77478",
    font_dark_blue: "#00008B",
    font_light_gray: "#C4C4C4",
    font_dark_gray: "#878491",
    error_red: "#FF0000",
    geraldine: "#E77478",
    blue: "#506FA6",
    dark_blue: "#0F0821",
    blue_gray: "#566EA4",
    light_pink: "#F58E8A",
    gray: "rgba(15, 9, 35, 0.5)",
    light_gray: "rgba(15, 9, 35, 0.3)",
    lighter_gray: "rgba(15, 9, 35, 0.1)",
    bg_white: "#ffffff",
    bg_black: "#0F0824",
    bg_pink: "#FDF4F4",
    bg_blue: "#4F6EA8",
    font_gray: "#E5E5E5",
    pale_blue: "#F6F6FA",
    darker_pale_blue: "#D5DCEA",
    yellow: "#ffd200",
    reviews_pink: "#E77478",
    reviews_green: "#64B37E",
    reviews_yellow: "#f3c937",
    dark_blue: "#12142E",
    blue_yonder: "#566DA4",
    light_white: "#E0E0E0",
    gold: "#EDB186"
  },
  font: {
    effra_regular: "Effra-Regular",
    effra_medium: "Effra-Medium",
    effra_bold: "Effra-Bold"
  },
  fontWeight: {
    regular: "400",
    medium: "500",
    bold: "700",
    bolder: "900",
  },
  fontSize: {
    xs: {
      desktop: "1.6rem",
      mobile: "1.2rem",
    },
    sm: "1.5rem",
    base: {
      desktop: "1.6rem",
      mobile: "1.4rem",
    },
    lg: {
      desktop: "1.8rem",
      mobile: "1.6rem",
    },
    "3xl": {
      desktop: "3.6rem",
      mobile: "2.4rem",
    },
    "4xl": {
      desktop: "4.4rem",
      mobile: "2.4rem",
    },
    "6xl": {
      desktop: "8.8rem",
      mobile: "4rem",
    },
    // lg: "1.125rem",
    // xl: "1.25rem",
    // "2xl": "1.5rem",
    // "3xl": "1.875rem",
    // "4xl": "2.25rem",
    // "5xl": "3rem",
    // "6xl": "4rem",
  },
  lineHeight: {
    xs: {
      desktop: "1.8rem",
      mobile: "1.5rem",
    },
    base: {
      desktop: "3rem",
      mobile: "2.2rem",
    },
    lg: {
      desktop: "1.8rem",
      mobile: "1.8rem",
    },
    "3xl": {
      desktop: "4.4rem",
      mobile: "3.2rem",
    },
    "4xl": {
      desktop: "6rem",
      mobile: "3.2rem",
    },
    "6xl": {
      desktop: "8rem",
      mobile: "4.8rem",
    },
  },
  device: {
    smPhone: `(max-width: ${size.smPhone})`,
    phone: `(max-width: ${size.phone})`,
    tablet: `(min-width: ${size.tablet})`,
    smTablet: `(max-width: ${size.tablet})`,
    laptop: `(min-width: ${size.smallDesktop})`,
    largeDesktop: `(min-width: ${size.largeDesktop})`,
    phoneLandscape: `(min-width: ${size.phone}) and (max-height: ${size.phoneLandscapeSize})`,
    tabletLandscape: `(min-width: ${size.tablet}) and (max-height: ${size.tabletLandscapeSize})`,
    laptopDesktop: `(min-width: ${size.smallDesktop}) and (max-width: ${size.largeDesktop})`,
  },
};

export const GlobalStyle = createGlobalStyle`
    /* * {
        -webkit-overflow-scrolling: touch;
    } */

    html {
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
overflow - x: scroll!important; /* Must be 'scroll' not 'auto' */
      /* -webkit-overflow-scrolling: touch; */
     }

    p {
  padding: 0;
  margin: 0;
}
    ${sliderStyles}
    ${sliderTheme}
`;
