import { useStaticQuery, graphql } from "gatsby";

const useSearchData = () =>
  useStaticQuery(graphql`
    query SearchQuery {
      searchData: sanitySearch {
        _id
        trending {
          _key
          title
          link
        }
        notFoundProducts {
          _id
          content {
            main {
              title
              slug {
                current
              }
            }
          }
        }
        ctaTitle
        ctaButton {
          enabled
          link
          text
        }
      }
    }
  `);

export default useSearchData;
