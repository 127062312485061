import styled, { css } from "styled-components";

interface ContainerProps {
  small?: boolean;
  left?: boolean;
}

export const Container = styled.span<ContainerProps>`
  position: absolute;
  top: 1rem;
  right: -4.5rem;
  width: 13.4rem;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.geraldine};
  color: ${({ theme }) => theme.colors.font_white};
  transform: ${({ left }) => left ? 'rotate(-45deg)' : 'rotate(45deg)'};
  font-size: 1.1rem;
  line-height: 2.4rem;
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${({ small }) => small && smallRibbon}

  ${({ left }) => left && `
    left: -3rem !important;
    top: 3.5rem !important;
    width: 160px;
    right: unset !important;
  `}

  @media ${({ theme }) => theme.device.tablet} {
    margin-right: 0;
    font-size: ${({ theme }) => theme.fontSize.base.mobile};
    top: 2.1rem;
    right: -3rem;

    ${({ small }) => small && smallRibbon}
  }
`;

const smallRibbon = css`
  font-size: 0.8rem;
  line-height: 1.7rem;
  top: 1.3rem;
  right: -4.8rem;
`;
