import styled from 'styled-components';

interface CaseProps {
  isActive: boolean;
  increaseHeight: boolean;
}

export const Card = styled.div<CaseProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${(props) => (props.increaseHeight ? '90px' : '76px')};
  margin: 8px 0;
  padding: 15px 6px 15px 12px;
  border: 0.359016px solid #000000;
  border-radius: 10px;
  column-gap: 8px;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.isActive ? '0px 4px 10px rgba(0, 0, 0, 0.25)' : 'unset'};
  background-color: #fff;
  width: 48%;

  @media ${({ theme }) => theme.device.phone} {
    padding: 15px 6px 15px 12px;
    grid-template-columns: 47px 1fr;
    width: 100%;
  }
`;

export const CardDetail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: 4px;
  max-width: 80%;

  @media ${({ theme }) => theme.device.phone} { 
    max-width: 70%;
  }
`;

export const CaseTitle = styled.h5`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  text-transform: capitalize;
`;

export const CasePrice = styled.span`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
`;

export const CaseLink = styled.a`
  font-family: 'Effra-Regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-decoration: underline;
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 auto 0;
`;

export const QuantityButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;

  svg {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

export const NumberInput = styled.input`
  -moz-appearance: textfield;
  border: 0;
  padding: 0 0 0 0.9rem;
  min-width: 1.6rem;
  width: 0;
  box-sizing: content-box;
  /* background-color: ${(props) => (props.isQuantity ? '#F6F6FA' : '#FFF')}; */
  background-color: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 0;
  }
`;

export const CardImageContainer = styled.div`
  min-width: 56px;
  height: 56px;
  display: block;
  margin: auto;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  img {
    border-radius: 99999px !important;
  }

  @media ${({ theme }) => theme.device.phone} {
    width: 47px;
    height: 47px;
  }
`;

export const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: block;
  margin: 0 0 auto 0;
`;

export const CaseDescription = styled.div`
  p {
    font-size: 16px;
  }
  @media ${({ theme }) => theme.device.phone} { 
    p {
      font-size: 14px;
    }
    
  }
`;