import React from "react";

interface Props {
  className?: string;
  name:
  | "search"
  | "shopping-cart"
  | "burger"
  | "star"
  | "halfStar"
  | "arrow-right"
  | "arrow-right-white"
  | "arrow-left"
  | "arrow-down"
  | "instagram"
  | "plus"
  | "plus-second"
  | "minus-second"
  | "minus"
  | "QAplus"
  | "QAminus"
  | "question"
  | "right"
  | "close"
  | "close-thick"
  | "video-play"
  | "video-play-second"
  | "pink-plus"
  | "tag"
  | "warning"
  |  "bundle-tick"
  | "sleep-emoji";
  color?: string;
  onClick?: () => void;
  filledWidth?: string;
  fieldName?: string;
}

const Icon = ({ className, name, color, onClick, filledWidth, fieldName }: Props) => {
  switch (name) {
    case "search":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
        >
          <path
            d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.4431 16.4438L20.9994 21.0002"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "pink-plus":
      return (
        <svg
          width="30"
          height="30"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.5 34.0541C26.6426 34.0541 34.0541 26.6426 34.0541 17.5C34.0541 8.35745 26.6426 0.945946 17.5 0.945946C8.35745 0.945946 0.945946 8.35745 0.945946 17.5C0.945946 26.6426 8.35745 34.0541 17.5 34.0541ZM17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z"
            fill="#F97878"
          />
          <path
            d="M16.3121 23.8H18.6879V18.2785H24.5V16.0215H18.6879V10.5H16.3121V16.0215H10.5V18.2785H16.3121V23.8Z"
            fill="#F97878"
          />
        </svg>
      );

    case "shopping-cart":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
        >
          <g clipPath="url(#clip0)">
            <path
              d="M3.75 6H20.1014C20.2113 6 20.3198 6.02413 20.4193 6.07068C20.5188 6.11724 20.6068 6.18508 20.6772 6.26941C20.7476 6.35374 20.7986 6.45251 20.8266 6.55872C20.8546 6.66494 20.859 6.77601 20.8393 6.88409L19.2039 15.8841C19.1725 16.0569 19.0814 16.2132 18.9466 16.3258C18.8117 16.4383 18.6417 16.5 18.466 16.5H6.29237C6.11679 16.5 5.94678 16.4384 5.81195 16.3259C5.67713 16.2135 5.58603 16.0573 5.55453 15.8845L3.18041 2.86545C3.14892 2.69273 3.05782 2.53652 2.92299 2.42406C2.78817 2.3116 2.61816 2.25 2.44258 2.25H0.75"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.75 21.75C7.57843 21.75 8.25 21.0784 8.25 20.25C8.25 19.4216 7.57843 18.75 6.75 18.75C5.92157 18.75 5.25 19.4216 5.25 20.25C5.25 21.0784 5.92157 21.75 6.75 21.75Z"
              fill="white"
            />
            <path
              d="M18 21.75C18.8284 21.75 19.5 21.0784 19.5 20.25C19.5 19.4216 18.8284 18.75 18 18.75C17.1716 18.75 16.5 19.4216 16.5 20.25C16.5 21.0784 17.1716 21.75 18 21.75Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case "star":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M18.6849 7.61047C18.6069 7.36549 18.4568 7.14971 18.2543 6.99129C18.0518 6.83287 17.8062 6.73917 17.5497 6.72241L12.9098 6.39771L11.1886 2.0636C11.0954 1.82399 10.9321 1.61811 10.7199 1.47293C10.5078 1.32775 10.2567 1.25004 9.99961 1.25H9.99839C9.74171 1.25046 9.49114 1.32836 9.27945 1.47352C9.06775 1.61867 8.90479 1.82433 8.81186 2.0636L7.06504 6.42273L2.45078 6.72241C2.19421 6.73917 1.94865 6.83287 1.74614 6.99129C1.54362 7.14971 1.39356 7.36549 1.31552 7.61047C1.2332 7.85894 1.2276 8.12643 1.29945 8.37813C1.3713 8.62982 1.51727 8.85404 1.71836 9.02161L5.26816 12.0221L4.21225 16.1755C4.13947 16.4553 4.15286 16.7505 4.25065 17.0226C4.34845 17.2946 4.5261 17.5308 4.76035 17.7002C4.98651 17.8647 5.25697 17.9574 5.53652 17.9661C5.81607 17.9748 6.09176 17.8992 6.32773 17.749L9.98984 15.4291C9.99472 15.4254 9.99961 15.423 10.0106 15.4291L13.951 17.9254C14.1649 18.0621 14.4151 18.131 14.6689 18.1232C14.9226 18.1153 15.1681 18.0311 15.3731 17.8815C15.5854 17.728 15.7464 17.5141 15.835 17.2676C15.9237 17.0212 15.9359 16.7537 15.87 16.5002L14.7481 11.9629L18.2821 9.02161C18.4832 8.85404 18.6291 8.62982 18.701 8.37813C18.7728 8.12643 18.7672 7.85894 18.6849 7.61047Z"
            fill={color}
          />
        </svg>
      );

    case "burger":
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
        >
          <path
            d="M4.0625 13H21.9375"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.0625 6.5H21.9375"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.0625 19.5H21.9375"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "arrow-right":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
        >
          <path
            d="M3.125 10L16.875 10"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.25 15.625L16.875 10L11.25 4.375"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "arrow-right-white":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
        >
          <path
            d="M3.125 10L16.875 10"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.25 15.625L16.875 10L11.25 4.375"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "arrow-left":
      return (
        <svg
          className={className}
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27 16L5 16"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 7L5 16L14 25"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "arrow-down":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.371"
          height="15.25"
          viewBox="0 0 13.371 15.25"
        >
          <g id="svgexport-7" transform="translate(16.686 -2.375) rotate(90)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M3.125,10h13.75"
              fill="none"
              stroke="#0f0923"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Path_2"
              data-name="Path 2"
              d="M11.25,15.625,16.875,10,11.25,4.375"
              fill="none"
              stroke="#0f0923"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
          </g>
        </svg>
      );

    case "instagram":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
            stroke="#506FA6"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M21.5 4.5H10.5C7.18629 4.5 4.5 7.18629 4.5 10.5V21.5C4.5 24.8137 7.18629 27.5 10.5 27.5H21.5C24.8137 27.5 27.5 24.8137 27.5 21.5V10.5C27.5 7.18629 24.8137 4.5 21.5 4.5Z"
            stroke="#506FA6"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z"
            fill="#506FA6"
          />
        </svg>
      );

    case "plus-second":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 16H21"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 11V21"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "plus":
      return (
        <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
          stroke="#0F0923"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 16H21"
          stroke="#0F0923"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 11V21"
          stroke="#0F0923"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      )

    case "minus":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 16H21"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

      case "minus-second":
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 16H21"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "QAplus":
      return (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.48542 17.9706L9.48542 1.00007"
            stroke="#200E32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.00014 9.48535L17.9707 9.48535"
            stroke="#200E32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );

    case "QAminus":
      return (
        <svg
          width="19"
          height="3"
          viewBox="0 0 19 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.00014 1.48535L17.9707 1.48535"
            stroke="#200E32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );

    case "question":
      return (
        <svg
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          className={className}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.0002 40.25C32.5272 40.25 40.2502 32.5269 40.2502 23C40.2502 13.4731 32.5272 5.75 23.0002 5.75C13.4733 5.75 5.75024 13.4731 5.75024 23C5.75024 32.5269 13.4733 40.25 23.0002 40.25Z"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 34.1406C23.9924 34.1406 24.7969 33.3361 24.7969 32.3438C24.7969 31.3514 23.9924 30.5469 23 30.5469C22.0076 30.5469 21.2031 31.3514 21.2031 32.3438C21.2031 33.3361 22.0076 34.1406 23 34.1406Z"
            fill="#0F0923"
          />
          <path
            d="M23 25.8755V24.438C23.9951 24.438 24.9678 24.1429 25.7952 23.5901C26.6226 23.0372 27.2675 22.2515 27.6483 21.3321C28.0291 20.4128 28.1287 19.4012 27.9346 18.4252C27.7404 17.4492 27.2613 16.5527 26.5576 15.8491C25.854 15.1455 24.9575 14.6663 23.9815 14.4722C23.0056 14.278 21.994 14.3777 21.0746 14.7585C20.1553 15.1393 19.3695 15.7841 18.8167 16.6115C18.2638 17.4389 17.9688 18.4117 17.9688 19.4067"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "right":
      return (
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M5.625 2.8125L10.3125 7.5L5.625 12.1875"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "close":
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
        >
          <path
            d="M2 24.5366L24.5355 2.0011"
            // stroke="#0F0923"
            stroke={color ? color : "#0F0923"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 2L24.5355 24.5355"
            // stroke="#0F0923"
            stroke={color ? color : "#0F0923"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "close-thick":
      return (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          onClick={onClick}
        >
          <path
            d="M2 19.001L18.9992 2.00181"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 2L18.9992 18.9992"
            stroke="#0F0923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case "video-play":
      return (
        <svg
          width="85"
          height="85"
          viewBox="0 0 85 85"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M64.5477 41.3298L33.381 20.0798C32.9461 19.7851 32.3851 19.7525 31.9204 19.9976C31.4572 20.2427 31.1667 20.7243 31.1667 21.2499V63.7499C31.1667 64.2755 31.4572 64.7572 31.9218 65.0023C32.1287 65.1128 32.3567 65.1666 32.5834 65.1666C32.8625 65.1666 33.1416 65.083 33.381 64.9201L64.5477 43.6701C64.9344 43.4066 65.1667 42.9688 65.1667 42.4999C65.1667 42.031 64.9344 41.5933 64.5477 41.3298ZM34.0001 61.0682V23.9317L61.2355 42.4999L34.0001 61.0682Z"
            fill="white"
          />
          <path
            d="M42.5 0C19.0655 0 0 19.0655 0 42.5C0 65.9345 19.0655 85 42.5 85C65.9345 85 85 65.9345 85 42.5C85 19.0655 65.9345 0 42.5 0ZM42.5 82.1667C20.6281 82.1667 2.83333 64.3719 2.83333 42.5C2.83333 20.6281 20.6281 2.83333 42.5 2.83333C64.3719 2.83333 82.1667 20.6281 82.1667 42.5C82.1667 64.3719 64.3719 82.1667 42.5 82.1667Z"
            fill="white"
          />
        </svg>
      );
    case "video-play-second":
      return (
        <svg
          width="24"
          height="22"
          viewBox="0 0 24 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <rect x="-0.00012207" width="23.7761" height="21.1343" fill="url(#pattern0)" />
          <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use transform="translate(0 -0.00624998) scale(0.0125 0.0140625)" />
            </pattern>
            <image id="image0_1_590" width="80" height="72"/>
          </defs>
        </svg>
      );
    

    case "sleep-emoji":
      return (
        <svg
          id="emoji"
          viewBox="0 0 72 72"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="line">
            <polyline
              fill="none"
              stroke="#0051C9"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="6"
              points="49,8 62.1429,8 49,31 62.1429,31"
            />
            <polyline
              fill="none"
              stroke="#0051C9"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="6"
              points="28.1429,33.75 37,33.75 28.1429,49.25 37,49.25"
            />
            <polyline
              fill="none"
              stroke="#0051C9"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="4.6452"
              points="9.1429,52.5 16,52.5 9.1429,64.5 16,64.5"
            />
          </g>
        </svg>
      );
    case "tag":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
          <path
            d="m483.24 0h-150.29c-27.556 0-66.04 15.94-85.52 35.424l-232.81 232.81c-19.483 19.483-19.483 51.37 0 70.85l179.64 179.64c19.483 19.484 51.37 19.484 70.849 0l232.81-232.81c19.483-19.484 35.424-57.969 35.424-85.52v-150.29c-.0001-27.554-22.544-50.1-50.1-50.1m-66.57 166.67c-27.614 0-50-22.385-50-50 0-27.614 22.386-50 50-50 27.614 0 50 22.386 50 50 0 27.614-22.386 50-50 50z"
            fill="#4d4d4d"
            transform="matrix(.03 0 0 .03 2.994 2.992)"
          />
        </svg>
      );

    case "warning":
      return (
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 1280.000000 1126.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,1126.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              d="M6201 11240 c-41 -10 -113 -37 -160 -61 -70 -35 -105 -62 -187 -144
-61 -60 -124 -134 -157 -185 -85 -132 -681 -1182 -2962 -5215 -793 -1402
-1714 -3032 -2047 -3620 -333 -589 -617 -1098 -631 -1131 -79 -187 -72 -394
19 -559 15 -28 64 -86 108 -130 91 -90 177 -139 306 -175 l76 -20 5879 2 5880
3 81 27 c363 124 494 499 304 878 -21 43 -899 1580 -1951 3417 -1052 1836
-2308 4029 -2791 4873 -484 844 -909 1580 -946 1635 -118 177 -268 311 -419
373 -125 52 -272 64 -402 32z m1607 -3410 c793 -1383 2019 -3523 2724 -4755
l1283 -2240 -2712 -3 c-1492 -1 -3934 -1 -5427 0 l-2715 3 1666 2945 c3188
5637 3725 6583 3734 6572 4 -4 655 -1139 1447 -2522z"
            />
            <path
              d="M6290 7874 c-14 -3 -61 -14 -104 -25 -390 -98 -706 -474 -706 -837 0
-46 22 -254 50 -461 27 -207 113 -857 190 -1446 201 -1535 199 -1517 216
-1581 42 -165 141 -297 271 -361 67 -33 86 -38 168 -41 152 -7 246 30 348 136
99 105 144 224 176 464 11 84 61 462 111 841 49 378 131 996 180 1375 50 378
100 756 111 840 24 182 25 305 4 387 -82 323 -360 599 -693 686 -75 20 -266
33 -322 23z"
            />
            <path
              d="M6322 2739 c-345 -44 -594 -371 -552 -726 20 -166 86 -301 204 -410
114 -107 237 -160 391 -170 187 -11 336 47 475 187 134 134 192 273 193 465 1
116 -13 183 -58 280 -120 261 -379 409 -653 374z"
            />
          </g>
        </svg>
      );

    case "bundle-tick":
      return (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="xMidYMin slice" viewBox="0 0.75 14 14">
            <defs/>
            <path d="M 0 7.75 C 0 3.895 3.117 0.75 7 0.75 C 10.856 0.75 14 3.895 14 7.75 C 14 11.633 10.856 14.75 7 14.75 C 3.117 14.75 0 11.633 0 7.75 Z M 10.145 6.547 C 10.445 6.246 10.445 5.781 10.145 5.48 C 9.844 5.18 9.379 5.18 9.078 5.48 L 6.125 8.434 L 4.895 7.23 C 4.594 6.93 4.129 6.93 3.828 7.23 C 3.527 7.531 3.527 7.996 3.828 8.297 L 5.578 10.047 C 5.879 10.348 6.344 10.348 6.645 10.047 L 10.145 6.547 Z" fill="#566DA3"/>
          </svg>
      );

      case "halfStar":
        return (
          <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <defs>
              <linearGradient id={`half-half-${fieldName}`} x1="0" y1="0" x2="100%" y2="0">
              <stop offset={filledWidth + "%"} stop-color={color} />
              <stop offset={filledWidth + "%"} stop-color="#d3d3d3" />
            </linearGradient>
          </defs>
         <path
            d="M18.6849 7.61047C18.6069 7.36549 18.4568 7.14971 18.2543 6.99129C18.0518 6.83287 17.8062 6.73917 17.5497 6.72241L12.9098 6.39771L11.1886 2.0636C11.0954 1.82399 10.9321 1.61811 10.7199 1.47293C10.5078 1.32775 10.2567 1.25004 9.99961 1.25H9.99839C9.74171 1.25046 9.49114 1.32836 9.27945 1.47352C9.06775 1.61867 8.90479 1.82433 8.81186 2.0636L7.06504 6.42273L2.45078 6.72241C2.19421 6.73917 1.94865 6.83287 1.74614 6.99129C1.54362 7.14971 1.39356 7.36549 1.31552 7.61047C1.2332 7.85894 1.2276 8.12643 1.29945 8.37813C1.3713 8.62982 1.51727 8.85404 1.71836 9.02161L5.26816 12.0221L4.21225 16.1755C4.13947 16.4553 4.15286 16.7505 4.25065 17.0226C4.34845 17.2946 4.5261 17.5308 4.76035 17.7002C4.98651 17.8647 5.25697 17.9574 5.53652 17.9661C5.81607 17.9748 6.09176 17.8992 6.32773 17.749L9.98984 15.4291C9.99472 15.4254 9.99961 15.423 10.0106 15.4291L13.951 17.9254C14.1649 18.0621 14.4151 18.131 14.6689 18.1232C14.9226 18.1153 15.1681 18.0311 15.3731 17.8815C15.5854 17.728 15.7464 17.5141 15.835 17.2676C15.9237 17.0212 15.9359 16.7537 15.87 16.5002L14.7481 11.9629L18.2821 9.02161C18.4832 8.85404 18.6291 8.62982 18.701 8.37813C18.7728 8.12643 18.7672 7.85894 18.6849 7.61047Z"
            fill={`url(#half-half-${fieldName})`}
          />
          </svg>
        
        );

    default:
      return null;
  }
};

export default Icon;
