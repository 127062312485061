import styled, { css } from "styled-components";
import { Paragraph, SectionHeading } from "../Text";
import BlockContent from "src/components/BlockContent";
import Link from "gatsby-link";
import { size } from "src/theme/globalStyle";
import Image from "../Common/ImageFluid";
import Rating from "src/components/Rating";

interface InfoProps {
  bigPadding: boolean;
}

interface ContLinkProps {
  mobileSmall?: boolean;
}

interface TextContProps {
  addToCart?: boolean;
}

interface LinkProps {
  disabled?: boolean;
  btnBackground?: any;
  buttonsInBlack?: boolean;
}


interface LabelProps {
  background?: any;
  color?: any;
}


export const TextContainer = styled.div<TextContProps>`
  margin-bottom: ${({ addToCart }) => (addToCart ? "2rem" : "4.1rem")};
`;

export const DescriptionHeading = styled(SectionHeading)`
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 2rem;
    max-width: 41rem;
  }
`;

export const Description = styled(BlockContent)`
  white-space: pre-line;
  color: ${({ theme, color }) => theme.colors[color as string]};
  font-size: ${({ theme }) => theme.fontSize.base.mobile};
  line-height: ${({ theme }) => theme.lineHeight.base.mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize.base.desktop};
    line-height: ${({ theme }) => theme.lineHeight.base.desktop};
    margin: 3.3rem 0 6.5rem;
    max-width: 41rem;
  }
`;

export const Heading = styled.h2`
  margin-bottom: 1rem;
  font-size: ${({ theme }) => theme.fontSize.lg.desktop};
  line-height: ${({ theme }) => theme.lineHeight.lg.desktop};
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  &.strikethrough_price {
    text-decoration: line-through;
    opacity: 0.35;
    margin-right: 0.5rem;
  }
`;

export const PriceHeading = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSize["4xl"].mobile};
`;

export const InfoContainer = styled.div<InfoProps>`
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  height: 52%;
  width: 100%;
  justify-content: space-between;

  @media ${({ theme }) => theme.device.laptop} {
    /* padding: 2.2rem 3.5rem 3rem; */
    padding: ${({ bigPadding }) =>
      bigPadding ? "2.2rem 6rem 3rem" : "2.2rem 3.5rem 3rem"};
    display: flex;
    flex-direction: column;
    align-items: center;
  } 
`;

export const StyledRating = styled(Rating)`
  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: auto;
  }
`;

export const CartLink = styled(SectionHeading)<LinkProps>`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({buttonsInBlack}) => (buttonsInBlack ? "1.8rem" : "1.2rem")};
  line-height: 1.8rem;
  margin-top: 0.8rem;
  padding: ${({btnBackground, buttonsInBlack}) => (btnBackground ? "1.1rem 2.5rem" : buttonsInBlack ? "1.5rem 2.2rem" : "0")};
  border-radius: ${({buttonsInBlack}) => (buttonsInBlack ? "1rem" : "4px")};
  width: fit-content;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: ${({buttonsInBlack}) => (buttonsInBlack ? "#000" : "#fff")};
  color: ${({buttonsInBlack}) => (buttonsInBlack ? "#fff" : "#000")};
  box-shadow: ${({ buttonsInBlack }) =>
    buttonsInBlack ? "0px 2px 4px rgba(0, 0, 0, 0.15)" : "none"};
  align-self: ${({ buttonsInBlack }) =>
    buttonsInBlack ? "center" : "start"};
  
  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({buttonsInBlack}) => (buttonsInBlack ? "1.8rem" : "1.8rem")};
    padding: ${({btnBackground, buttonsInBlack}) => (btnBackground ? "1.1rem 2.5rem" : buttonsInBlack ? "1.8rem 2.4rem" : "0")};
    margin-top: 4.7rem;
    align-self: center;
    /* margin-top: auto; */
  }
`;

export const ContainerLink = styled(Link)<ContLinkProps>`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.bg_white};
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: visible;
  align-items: center;

  @media ${({ theme }) => theme.device.laptop} {
    display: flex;
    flex-direction: column;
    justify-content: unset;
  }
  @media ${({ theme }) => theme.device.phone}  {
    ${({ mobileSmall }) =>
      mobileSmall &&
      css`
        ${InfoContainer} {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      `
    }
  }
  @media ${({ theme }) => theme.device.smPhone}  {
    ${({ mobileSmall }) =>
      mobileSmall &&
      css`
        ${InfoContainer} {
          display: flex;
          flex-direction: column;
          justify-content: end;
        }
      `
    }
  }

  @media (max-width: ${size.smallDesktop}) {
    ${({ mobileSmall }) =>
      mobileSmall &&
      css`
        ${InfoContainer} {
          padding: 0.8rem 1.2rem 0.8rem;
          /* padding: 0.6rem 1.2rem 1.7rem; */
          /* padding: 0.3rem 1.2rem 1.7rem; */
        }

        ${Heading} {
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin-bottom: 0.3rem;
        }

        ${PriceHeading} {
          font-size: 1.2rem;
          line-height: 1.4rem;
        }

        ${TextContainer} {
          /* margin-bottom: 1.2rem; */
          margin-bottom: 0.6rem;
        }

        ${StyledRating} {
          svg {
            width: 1rem;
            margin-right: 0.25rem;
          }
        }
      `}
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60%;

  @media ${({ theme }) => theme.device.phone} { 
    width: 100%;
    height: 48%;
  }

  /* &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  } */
`;

export const StyledImage = styled(Image)`
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; */
`;

export const DescriptionParagraph = styled(BlockContent)`
  color: ${({ theme }) => theme.colors.font_black};
  font-size: ${({ theme }) => theme.fontSize.base.mobile};
  line-height: ${({ theme }) => theme.lineHeight.base.mobile};
  font-family: 'Effra-Regular';
  font-weight: 400;
  margin: 2rem 0;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 0 0 3rem;
    font-size: ${({ theme }) => theme.fontSize.base.desktop};
    line-height: ${({ theme }) => theme.lineHeight.base.desktop};
  }
`;
export const OkendoReviews = styled.div`
  .okeReviews .okeReviews-reviewsSummary {
    display: flex;    
    flex-direction: column;
    align-items: flex-start;
    @media ${({ theme }) => theme.device.laptop} {
      align-items: center;
    }
  }
`;
export const NewProductMessage = styled.div`
  padding: 6px 11px;
  text-align: center;
  color: #FEF9F9;
  font-weight: 400;
  font-size: 9px;
  background: #4F6EA8;
  border-radius: 57px;
  width: fit-content;
  font-family: 'Effra-Regular';
  font-style: normal;
`

export const RatingsBox = styled.div`
  display: flex;    
  flex-direction: column;
  align-items: flex-start;
  @media ${({ theme }) => theme.device.laptop} {
    align-items: center;
  }
`;


export const RatingParagraph = styled(Paragraph)`
  span {
    margin-right: .5rem;
    font-size: 15px;
  }
  @media ${({ theme }) => theme.device.phone} {
    span{
      font-size: 11px;
    }
  }
`;

export const Label = styled.div<LabelProps>`
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 13.4rem;
  padding: 1.3rem 1rem;
  border-radius: 1rem;
  text-align: center;
  background-color: ${({ background }) => background.hex};
  color: ${({ color }) => color.hex};
  font-size: 2rem;
  line-height: 2rem;
  font-family: 'Effra-Regular';
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 2.3rem;
    line-height: 2.4rem;
    padding: 1.3rem 1.6rem;
  }
`;