import React from "react";
import { RatingsList, RatingStar } from "./styles";

interface Props {
  number: number;
  color: string;
  fieldName: string;
  onClick?: () => void;
}


const Rating = ({ number, color, fieldName, ...props }: Props) => {
  const renderStars = () => {
    const stars = [];
    const wholeStars = Math.floor(number);
    const decimalPart = number % 1;

    // Render whole stars
    for (let i = 0; i < wholeStars; i++) {
      stars.push(<RatingStar key={i} name="star" color={color} />);
    }

    // Render partially filled star if applicable
    if (wholeStars < 5) {
      const filledWidth = decimalPart * 100;
      const key = wholeStars;
      const starProps = {
        key,
        name: wholeStars === 0 ? "star" : "halfStar",
        fieldName,
        color: wholeStars === 0 ? "#d3d3d3" : color,
        filledWidth
      };
    
      const halfStar = <RatingStar {...starProps} />;
      stars.push(halfStar);
    }
    
    // Render empty stars
    for (let i = wholeStars + 1; i < 5; i++) {
      stars.push(<RatingStar key={i} name="star" color="#d3d3d3" />);
    }

    return stars;
  };

  return <RatingsList {...props}>{renderStars()}</RatingsList>;
};


export default Rating;