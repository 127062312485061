import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  FooterContainer,
  FooterHeader,
  StyledInput,
  FormContainer,
  SubmitButton,
  Logo,
  FooterNav,
  FooterNavItem,
  Caption,
  NavHeading,
  NavParagraph,
  SuccessMessage,
  SocialIconsContainer,
} from "./styles";
import Wrap from "../../components/Wrap";
import Link from "src/components/Link";
import Instagram from "src/images/instagram.svg";
import Facebook from "src/images/fb.svg";
import Youtube from "src/images/youtube.svg";
import Linkedin from "src/images/linkedin.svg";
import WNW from "src/images/wnw.svg";
import Twitter from "src/images/twitter.svg";
import Dribble from "src/images/dribble.svg";
import Vimeo from "src/images/vimeno.svg";
import Figma from "src/images/figma.svg";
import Behance from "src/images/behance.svg";
import Spotify from "src/images/spotify.svg";
import Ello from "src/images/ello.svg";
import { ItemContent } from "../NewFAQSection/styles";

const icons = {
  facebook: Facebook,
  instagram: Instagram,
  youtube: Youtube,
  linkedin: Linkedin,
  twitter: Twitter,
  wnw: WNW,
  dribble: Dribble,
  vimeo: Vimeo,
  figma: Figma,
  behance: Behance,
  spotify: Spotify,
  ello: Ello
};

const Footer = ({ socialIcons }) => {
  
  const [inputVal, setInputVal] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const { footer, logo } = useStaticQuery(graphql`
    query FooterQuery {
      footer: sanityFooter {
        _rawInput
        _rawMenuList
      }
      logo: sanityLogoImage {
        _rawImage
      }
    }
  `);

  const subscribe = async (e: Event) => {
    e.preventDefault();

    if (inputVal === "") return;

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputVal,
      }),
    };

    const res = await fetch("/.netlify/functions/klaviyo", options);

    const data = await res.json();

    if (!data || data.error) return;

    setSuccess(true);
  };

  const SocialIcons = ({ list = [
    "linkedin",
    "instagram",
    "wnw",
    "twitter",
    "dribble",
    "vimeo",
    "figma",
    "behance",
    "spotify",
    "ello",
    "youtube",
    "facebook"
  ], social }) => {

    return (
      <SocialIconsContainer>
        {social &&
          social?.map((socialItem) => {
            return list?.map((item) => {
              if (socialItem?.name === item) {
                return (
                  <Link key={item} to={socialItem?.url}>
                     <img src={icons[item]} />
                  </Link>
                );
              }
              return null; 
            });
          })}
      </SocialIconsContainer>
    );
  };


  const renderMobile = (
    <>
      <FooterHeader>
        <div>
          <Logo imageRef={logo._rawImage} />
        </div>
        <Caption>{footer._rawInput.caption}</Caption>
        <FormContainer onSubmit={subscribe}>
          {success ? (
            <SuccessMessage>{footer._rawInput.successMessage}</SuccessMessage>
          ) : (
            <>
              <StyledInput
                placeholder={footer._rawInput.placeholder}
                type="email"
                required={true}
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
              />
              <SubmitButton
                color={footer._rawInput?.buttonTextColor?.value}
                backgroundColor={footer._rawInput?.buttonBackgroundColor?.value}
                htmlType="submit"
              >
                {footer._rawInput.buttonText}
              </SubmitButton>
            </>
          )}
        </FormContainer>
      </FooterHeader>
      <FooterNav>
        {footer._rawMenuList.map((listItem) => (
          <FooterNavItem key={listItem._key}>
            <NavHeading>{listItem.title}</NavHeading>
            {listItem.items &&
              listItem.items.map((item) => {
                switch (item._type) {
                  case "paragraph":
                    return (
                      <NavParagraph key={item._key}>{item.text}</NavParagraph>
                    );

                  case "menuListLink":
                    return (
                      <Link to={item.linkUrl} key={item._key}>
                        <NavParagraph>{item.linkText}</NavParagraph>
                      </Link>
                    );
                }
              })}
            {listItem?.showSocialIcons && <SocialIcons social={socialIcons} />}
          </FooterNavItem>
        ))}
      </FooterNav>
    </>
  );


  return (
    <FooterContainer>
      <Wrap>{renderMobile}</Wrap>
    </FooterContainer>
  );
};

export default Footer;
