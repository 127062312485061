import React, { useEffect } from 'react'
import hljs from 'highlight.js'
import javascript from 'highlight.js/lib/languages/javascript'
import 'highlight.js/styles/github.css'
import getImagePath from './getImagePath'

const CodeSnippet = ({ code }: { code: [] }) => {
  useEffect(() => {
    hljs.registerLanguage('javascript', javascript);
    document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightBlock(block);
    });
  });
  return (
    <pre>
      <code>{code}</code>
    </pre>
  );
};

const LinkHeader = ({ mark, children }) => (
  <span id={mark?.href} className={headerLinkWrapper}>
    { children }
  </span>
);

const smallText = ({ mark, children }) => (
  <small id={mark?.small} className={smallTxt}>
    {children}
  </small>
);

const ImageRenderer = ({ node }) => {
  return (
    <img className="rich-text-img" srcSet={getImagePath(node, true)} alt={node.alt} />
  );
};

const ButtonRenderer = ({ node: { link, text } }) => (
  <StyledButton to={link} className="rich-text-btn">
    { text }
  </StyledButton>
);

const highlight = (props) => <span style={{ backgroundColor: props.mark.color }}>{props.children}</span>;

const ColorRender = (node) => { 
  return React.createElement(node._type ,{ style: { color: node.mark.hex } },[...node.children]);
};


const TableSerializer = ({ node } = node) => {
  let headers = node.rows[0].cells.map((header, i) => { return <th key={i}>{header}</th>; });
  let tableRows = node.rows.slice(1, Infinity).map((tr,i) => { return <tr key={i}> {tr.cells.map((cells, i) => { return <td key={i}>{cells}</td>; })}</tr>;});

  return (
    <div className="table">
      <table>
        <tr>{headers}</tr>
        {tableRows}
      </table>
    </div>
  ); 
};

const TableTwoHeadersSerializer = ({ node } = node) => {
  let headers = node.rows[0].cells.map((header, i) => { return <th key={i}>{header}</th>; });
  let tableRows = node.rows.slice(1, Infinity).map((tr,i) => { return <tr key={i}> {tr.cells.map((cells, i) => { return <td className="side-header" key={i}>{cells}</td>; })}</tr>;});

  return (
    <div className="table">
      <table>
        <tr>{headers}</tr>
        {tableRows}
      </table>
    </div>
  ); 
};

const embedHTMLSerializer = (props : any) => <div dangerouslySetInnerHTML={{ __html: props.node.html }} />


export const Serializer = {
  types: {
    embedHTML: (props: { node: { html: any } }) => {
      return <div dangerouslySetInnerHTML={{ __html: props.node.html }} />;
    },
    image: ImageRenderer,
    limitedImage: ImageRenderer,
    button: ButtonRenderer,
    table: TableSerializer,
    tableTwoHeaders: TableTwoHeadersSerializer,
    embedHTML: embedHTMLSerializer
  },
  marks: {
    tick: (props: { children: any }) => (
      <span className="tick">{props.children}</span>
    ),
    code: (props: { children: any }) => <CodeSnippet code={props.children} />,
    highlight: (props: { children: any }) => (
      <span style={{ color: '#F49F0A' }}>{props.children}</span>
    ),
    sectionHeader: LinkHeader,
    color: ColorRender,
    highlight: highlight, 
    small: smallText,
  },
};