import React from 'react';
import ProductList from '../ProductList';
import { DrawerTitle, Wrapper } from './styles';
import Accordion from 'src/components/Accordion';

interface Props {
  productCases: any;
  id: string;
}
const PillowcaseMaterial = ({ productCases, id }: Props) => {
  return (
    <Wrapper>
      <DrawerTitle>Material benefits</DrawerTitle>
      {productCases.length > 0 &&
        productCases.map((product: any) => (
          <Accordion
            title={product?.content?.shopify?.defaultVariant?.title}
            image={product?.content?.shopify?.image}
            content={product?.content}
            productId={product?._id}
            selectedId={id}
          />
        ))}
    </Wrapper>
  );
};

export default PillowcaseMaterial;
