import { Link } from "gatsby";
import styled, { css } from "styled-components";
import Icon from "../Icon";

interface ButtonProps {
  small?: boolean;
  buttonType?: "primary" | "outline" | "default";
  color?: string;
  bgColor?: string;
}

const getButtonStyles = (buttonType: "primary" | "outline" | "default") => {
  switch (buttonType) {
    case "primary":
      return primaryButton;

    case "outline":
      return outlineButton;

    case "default":
      return defaultButton;
  }
};

const defaultButton = css`
  background-color: ${({ theme }) => theme.colors.bg_white};
  color: ${({ theme }) => theme.colors.font_black};
  border: 0.1rem solid;
  border-color: ${({ theme }) => theme.colors.gray};
`;

const primaryButton = css`
  background-color: ${({ theme, bgColor }) => bgColor ? theme.colors[color as string] : theme.colors.geraldine};
  color: ${({ theme, color }) => color ? theme.colors[color as string] : theme.colors.font_white};
  box-shadow:  ${({ boxShadow}) => boxShadow ? "1px 1px 6px 1px rgba(0,0,0,0.64)" : "0px 0px 0px 0px rgba(0,0,0)"};
`;

const outlineButton = css`
  background-color: transparent;
  border: 0.1rem solid;
  border-color: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.font_black};
`;

const Button = styled.button<ButtonProps>`
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: 'Effra-Medium';
  padding: 0 5.3rem;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  height: ${({ small }) => (small ? "4rem" : "5rem")};
  text-align: center;
`;

export const StyledButton = styled(Button)`
  ${({ buttonType }) => getButtonStyles(buttonType)}
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const InternalLink = styled(Link)<ButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: 'Effra-Medium';
  padding: 0 5.3rem;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  height: ${({ small }) => (small ? "4rem" : "5rem")};
  ${({ buttonType }) => getButtonStyles(buttonType)}
  text-align: center;
`;

export const ExternalLink = styled(Link)<ButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: 'Effra-Medium';
  padding: 0 5.3rem;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  height: ${({ small }) => (small ? "4rem" : "5rem")};
  ${({ buttonType }) => getButtonStyles(buttonType)}
  text-align: center;
`;
