const extractGid = (gid: any) => gid?.match(/[^/]+$/i)[0];

const trackKlavyioAddedToCart = (cart: any, item: any) => {
  const mappedItems = cart?.lineItems?.map((it: any) => ({
      ProductID: +extractGid((it.variant.product.id)),
      SKU: it?.variant?.sku,
      ProductName: it?.title,
      Quantity: it?.quantity,
      ItemPrice: +it?.variant?.price,
      RowTotal: it?.quantity * Number(it?.variant?.price),
      ProductURL: `${process.env.GATSBY_URL}/products/${it?.variant?.product?.handle}`,
      ImageURL: it?.variant?.image?.src,
    })
  );
  const payload = {
    $value: Number(cart?.total),
    AddedItemProductName: item?.name,
    AddedItemProductID: item?.product_id,
    AddedItemSKU: item?.sku,
    AddedItemVariantID: item.id,
    AddedItemPrice: item?.price,
    AddedItemImageURL: item?.image,
    AddedItemQuantity: item?.quantity,
    CheckoutURL: cart?.webUrl,
    Items: mappedItems,
  };
  let _learnq = window._learnq || [];
  _learnq.push(["track", "Added to Cart", payload]);
};

export default trackKlavyioAddedToCart;