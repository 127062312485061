import React, { useState, useCallback } from "react";
import useSearchData from "src/hooks/useSearchData";
import { useReviewDrawer } from "src/context/siteContext";
import _ from "lodash";
import client from "@sanity/client";
import {
  SearchInput,
  SearchContainer,
  Results,
  ResultHeading,
  ResultList,
  ListItem,
  StyledCtaBlock,
  StyledLink,
} from "./styles";

const sanity = client({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID!,
  dataset: process.env.GATSBY_SANITY_DATASET,
  apiVersion: "2021-08-31",
  useCdn: true,
});

const titles = {
  search: "Searching now...",
  trending: "Trending searches:",
  results: "Check it out:",
  noResults: "Sorry, no matches found.\n Try that:",
};

const Search = ({}) => {
  const { searchData } = useSearchData();
  const { closeReviews } = useReviewDrawer();
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searched, setSearched] = useState<boolean>(false);
  const [results, setResults] = useState([]);

  const onSearch = (e) => {
    setSearchValue(e.target.value);

    if (e.target.value === "") {
      debouncedSearch.cancel();
      setSearched(false);
      setResults([]);

      return;
    }

    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      getData(value);
    }, 500),
    []
  );

  const getData = async (searchQuery: string) => {
    setLoading(true);
    const query = `*[_type == "productsShopify" && content.main.title match "${searchQuery}*"][0...10]`;
    const res = await sanity.fetch(query);

    setResults(res);
    setSearched(true);
    setLoading(false);
  };

  const renderSuggestions = () =>
    searchData.trending.map((trend) => (
      <ListItem key={trend._key}>
        <StyledLink to={trend.link} onClick={onLinkClick}>
          {trend.title}
        </StyledLink>
      </ListItem>
    ));

  const renderResults = () => {
    if (results.length > 0) {
      return results.map((result) => (
        <ListItem key={result._id}>
          <StyledLink
            to={`/products/${result.content.main.slug.current}`}
            onClick={onLinkClick}
          >
            {result.content.main.title}
          </StyledLink>
        </ListItem>
      ));
    }

    return searchData.notFoundProducts.map((product) => (
      <ListItem key={product._id}>
        <StyledLink
          to={`/products/${product.content.main.slug.current}`}
          onClick={onLinkClick}
        >
          {product.content.main.title}
        </StyledLink>
      </ListItem>
    ));
  };

  const renderHeading = () => {
    if (loading) return titles.search;

    if (!searched) return titles.trending;

    return results.length > 0 ? titles.results : titles.noResults;
  };

  const onLinkClick = () => {
    closeReviews();
  };

  return (
    <SearchContainer>
      <SearchInput
        placeholder="Search"
        onChange={onSearch}
        value={searchValue}
      />
      <Results>
        <ResultHeading>{renderHeading()}</ResultHeading>
        <ResultList>
          {!loading && (searched ? renderResults() : renderSuggestions())}
        </ResultList>
      </Results>
      <StyledCtaBlock
        heading={searchData.ctaTitle}
        button={searchData.ctaButton}
        closeReviews={closeReviews}
      />
    </SearchContainer>
  );
};

export default Search;
