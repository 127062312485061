import styled from "styled-components";

interface Props {
  noPadding?: boolean;
}

const Wrap = styled.div<Props>`

  &.variant_A {
    display: none;
  }
  
  padding: ${(props) => (props.noPadding ? "0" : "0 2rem")};

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 144rem;
    padding: ${(props) => (props.noPadding ? "0" : "0 8.1rem")};
    margin: 0 auto;
    box-sizing: border-box;
  }
`;

export default Wrap;
