import React, { useState, useCallback, useEffect } from "react";
import {
  useUpdateItemsFromCart,
  useRemoveItemFromCart,
  client,
} from "src/context/siteContext";
import Icon from "src/components/Common/Icon";
import {
  Container,
  ItemImg,
  InfoBox,
  TextBlock,
  Title,
  Description,
  Quantity,
  QuantityButton,
  NumberInput,
  Price,
  QuantityAndPrice
} from "./styles";
import _ from "lodash";
import locales from 'src/locales';

interface Props {
  id: string;
  className?: string;
  title: string;
  quantity: number;
  variant: {
    id: string;
    priceV2: string;
    image: string;
    compareAtPriceV2: string;
    title?: string;
    sku?: string;
  };
  customAttributes: Array<{
    key: string;
    value: string;
  }>;
  loadOldImage?: boolean;
}

const CartItem = ({
  id,
  className,
  title,
  quantity,
  variant,
  totalItems
}: Props) => {
  const updateItemsFromCart = useUpdateItemsFromCart();
  const { priceV2, compareAtPriceV2, sku, image } = variant;

  const [stateQuantity, setQuantity] = useState(quantity);
  const [isRemoved, setIsRemoved] = useState(false);
  const removeFromCart = useRemoveItemFromCart();

  useEffect(() => {
    setQuantity(quantity)
  }, [quantity])

  const updateQuantity = (qty: number) => {
    updateItemsFromCart({ id, quantity: qty });
    setQuantity(qty);
  };

  const itemImage = image && client.image.helpers.imageForSize(image, {
    maxWidth: 200,
    maxHeight: 150,
  });

  const remove = (productId: string) => {
    setIsRemoved(true);
    // GA4 remove from cart event
    dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
        items: [
          {
            item_id: sku,
            item_name: title,
            currency: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
            item_brand: "GroovePillows",
            item_variant: variant?.title,
            price: parseFloat(priceV2?.amount),
            quantity
          }
        ]
      }
    });
    removeFromCart(productId);
  };

  const changeAmount = (qty: number) => {
    if (qty === 0 || stateQuantity === 0) {
      setQuantity(qty);
      debouncedChange.cancel();
      remove(id);

      return;
    }

    setQuantity(qty);
    debouncedChange(qty);
  };

  const debouncedChange = useCallback(
    _.debounce((qty) => {

      updateItemsFromCart({ id, quantity: qty });
    }, 150),
    []
  );

  const freeGiftVariantId = "gid://shopify/ProductVariant/32876393955414";
  const freeEcourseVariantId = "gid://shopify/ProductVariant/40074074456150";
  const freeGiftVariantIdUS = "gid://shopify/ProductVariant/42756017750166";
  const freeGiftVariantIds = [ freeEcourseVariantId, freeGiftVariantIdUS ];
  const isEcourseProduct = freeGiftVariantIds.includes(variant.id) && totalItems !== 1 && quantity === 1;
  

  return !isRemoved ? (
    <Container className={className}>
      {itemImage && <ItemImg src={itemImage} alt={title} />}
      <InfoBox>
          <TextBlock id="variant_A_course_text">
            <Title>{isEcourseProduct ? <span>{title} <p className="hide-mobile">{locales?.cart?.ecourseFreeWithYourOrder}</p></span>: title}</Title>
            <Description>

              {variant?.title !== "Default Title" ?  variant.title :
              isEcourseProduct ?
              <>
                <p>{locales?.cart?.ecourseDescription}</p>
                <br/><p>{locales?.cart?.ecourseInstruction}</p>
              </>
              :              
              ""}                
            </Description>
          </TextBlock>
        <QuantityAndPrice>
        {freeGiftVariantIds.includes(variant?.id) && totalItems !== 1 && quantity === 1 ? "" :
        <Quantity>
          <QuantityButton
            type="button"
            onClick={() => changeAmount(stateQuantity - 1)}
          >
            <Icon name="minus" />
          </QuantityButton>
          <NumberInput
            type="number"
            value={stateQuantity}
            onChange={(e) =>
              updateQuantity(parseInt(e.currentTarget.value, 10))
            }
            name="quantity"
            readOnly={true}
            min="1"
            max="99"
          />
          <QuantityButton
            type="button"
            onClick={() => changeAmount(stateQuantity + 1)}
          >
            <Icon name="plus" />
          </QuantityButton>
        </Quantity>}
        <Price>{freeGiftVariantIds.includes(variant.id) && totalItems !== 1 && quantity === 1 ? <span><p className="hide-mobile">Free</p><p className="hide-desktop">Free with your order</p></span> : process.env.GATSBY_CURRENCY_SYMBOL + priceV2?.amount}</Price>
      </QuantityAndPrice>
      </InfoBox>
    </Container>
  ) : null;
};

export default CartItem;
