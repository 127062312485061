import React, { FC, ButtonHTMLAttributes } from "react";
import { StyledIcon, StyledButton, ExternalLink, InternalLink } from "./styles";
import { useTheme } from "styled-components";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: "primary" | "outline" | "default";
  iconType?: string;
  small?: boolean;
  to?: string;
}

const Button: FC<Props> = ({
  buttonType = "primary",
  iconType,
  children,
  small = false,
  to,
  ...props
}) => {
  const theme = useTheme();

  const renderButton = () => {
    return (
      <StyledButton {...props} small={small} buttonType={buttonType}>
        {children}
        {iconType && (
          <StyledIcon name={iconType} color={theme.colors.font_black} />
        )}
      </StyledButton>
    );
  };

  const renderLink = () => {
    const internal = to?.startsWith("#")
      ? true
      : /^\/(?!\/)/.test(to as string);

    return internal ? (
      <InternalLink {...props} small={small} buttonType={buttonType} to={to}>
        {children}
        {iconType && (
          <StyledIcon name={iconType} color={theme.colors.font_black} />
        )}
      </InternalLink>
    ) : (
      <ExternalLink
        {...props}
        small={small}
        buttonType={buttonType}
        href={to}
        target="__blank"
      >
        {children}
        {iconType && (
          <StyledIcon name={iconType} color={theme.colors.font_black} />
        )}
      </ExternalLink>
    );
  };

  return to ? renderLink() : renderButton();
};

export default Button;
