import styled from "styled-components";
import { Paragraph } from "../Text";
import Wrap from "../Wrap";
import BlockContent from "src/components/BlockContent";

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bg_blue};
  padding: 1rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 0;
  }
`;

export const StyledWrap = styled(Wrap)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding: 0.5rem 0;
  text-align: center;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 1rem 0;
    text-align: center;
  }

  .countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    @media ${({ theme }) => theme.device.phone} {
    margin: 1rem 0 0 0;
    }

    .number {
      font-size: 1.4rem;
    }
    .container {
      margin: 0.2rem 0.5rem;
      background-color: black;
      padding: 0.1rem 0.3rem;
      border-radius: 3px;
      width: 30px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
    .text {
      font-size: 0.9rem;
      margin: 0.5rem 0 0 0;
    }
  }
`;

export const SaleOffer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Effra-Regular';

  .code {
    background-color: #f97878;
    align-items: cente r;
    padding: 0.4rem 0.7rem;
    color: white;
    margin: 1rem;
    border-radius: 3px;

    @media ${({ theme }) => theme.device.phone} {
      padding: 0.2rem 0.5rem;
      font-size: 1.2rem;
    }
  }
`;

export const Text = styled(BlockContent)`
  font-size: 1.15rem;
  line-height: 1.5rem;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.font_white};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.5rem;
    line-height: 2.3rem;
  }

  .countdown {
    margin: 0 0 0 1rem;
    display: flex;
    flex-direction: row;
    color: white;

    .container {
      padding: 2px 0.3rem 0 0.3rem;
      margin: 0 0.5rem;
      background-color: black;
      border-radius: 3px;
    }

    .number {
      font-size: 1.9rem;
      font-weight: 400;
    }
    .text {
      font-size: 1.1rem;
    }
  }
`;

export const TextColor = styled(Paragraph)`
  font-size: 1.15rem;
  line-height: 1.5rem;
  color: #fee10b;
  text-align: center;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.5rem;
    margin: 0 0 0 0.5rem;
    display: flex;
    align-self: center;
  }
  @media ${({ theme }) => theme.device.laptop} {
    font-size: 1.5rem;
    line-height: 2.3rem;
    margin: 0 0 0 0.5rem;
  }
`;
export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  p {
    font-size: 14px;
  }
  .countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;

    .number {
      font-size: 1.4rem;
    }
    .container {
      margin: 0.4rem 0.5rem;
      background-color: black;
      padding: 0.1rem 0.3rem;
      border-radius: 3px;
      width: 30px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
    .text {
      font-size: 0.9rem;
      margin: 0.3rem 0 0 0;
    }
  }

  .code {
    background-color: #f97878;
    align-items: center;
    padding: 0.4rem 0.7rem;
    color: white;
    margin: 0.5rem;
    border-radius: 3px;

    @media ${({ theme }) => theme.device.phone} {
      padding: 0.2rem 0.5rem;
      font-size: 1.7rem;
      margin: 0.8rem;
    }
  }

  .discount-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

export const LinkedText = styled(Paragraph)`
  font-size: 1.15rem;
  line-height: 1.5rem;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  margin: 0 5px 5px 0;
  color: ${({ theme }) => theme.colors.font_white};

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.5rem;
    line-height: 2.3rem;
  }
`;

export const DesktopContainer = styled.div`
  display: none;
  .code {
    background-color: #f97878;
    align-items: cente r;
    padding: 0.4rem 0.7rem;
    color: white;
    margin: 0.3rem 0.5rem;
    border-radius: 3px;

    @media ${({ theme }) => theme.device.phone} {
      padding: 0.2rem 0.5rem;
      font-size: 1.2rem;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    align-items: flex-end;
  }
`;
