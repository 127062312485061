import styled from "styled-components";

export const StyledMain = styled.main`
  padding-top: 6.3rem;

  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 6.9rem;
  }
`;

export const StyledWrap = styled.div`
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;

  // Experiment

  #variant-b {
    display: none;
  }

  #variant-B {
    display: none;
  }
`;

