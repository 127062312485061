import React, { useEffect, useRef, lazy, Suspense } from 'react';
import { useStore, useReviewDrawer } from 'src/context/siteContext';
import {
  Drawer,
  Backdrop,
  BackdropTitle,
  StyledIcon,
  CloseIcon,
} from './styles';
import Search from 'src/components/Search';
import AdditionalInformation from 'src/components/AdditionalInformation';
import CartDrawer from 'src/components/NewCart';
import { isSSR } from 'src/utils/ssr';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import ReturnMoneyBack from '../ReturnMoneyBack';
import PillowcaseMaterial from 'src/components/PillowcaseMaterial';

const LazyWidget = lazy(() => import('src/components/OkendoWidget'));

function waitForElementToDisplay(
  selector,
  callback,
  checkFrequencyInMs,
  timeoutInMs
) {
  var startTimeInMs = Date.now();
  (function loopSearch() {
    if (document.querySelector(selector) != null) {
      callback();
      return;
    } else {
      setTimeout(function() {
        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) return;
        loopSearch();
      }, checkFrequencyInMs);
    }
  })();
}

interface Props {
  cartInfo: any;
  additionalInformationImage: {};
  productCases: [];
  caseText?: any;
  caseSubheader?: any; 
  returnMoneyBack?: Object[];
}

const SideDrawer = ({
  cartInfo,
  additionalInformationImage,
  productCases,
  caseText,
  caseSubheader,
  returnMoneyBack,
}: Props) => {
  const {
    drawerOpen,
    drawerType,
    productInfo,
    reviewsType,
    pillowcaseId,
  } = useStore();
  const drawerRef = useRef(null);

  const { closeReviews } = useReviewDrawer();

  useEffect(() => {
    if (isSSR || !drawerRef.current) return;

    if (drawerOpen) {
      document.documentElement.style.overflow = 'hidden';

      disableBodyScroll(drawerRef.current);
    } else {
      document.documentElement.style.overflow = 'auto';

      enableBodyScroll(drawerRef.current);
    }

    return () => clearAllBodyScrollLocks();
  }, [drawerOpen]);

  const renderContent = (
    productType:
      | 'search'
      | 'reviews'
      | 'cart'
      | 'returnMoney'
      | 'additionalInformation'
      | 'pillowcaseMaterial'
      | undefined
  ) => {
    if (!productType) return;

    switch (productType) {
      case 'search':
        return <Search />;

      case 'pillowcaseMaterial':
        return (
          <PillowcaseMaterial productCases={productCases} id={pillowcaseId} />
        );

      case 'additionalInformation':
        return (
          <AdditionalInformation
            additionalInformationImage={additionalInformationImage}
          />
        );

      case 'reviews':
        if (!productInfo) return;
       
        if (reviewsType === 'questions') {
          waitForElementToDisplay(
            '#tab-questions',
            () => {
              const questionTab = document.querySelector('#tab-questions');
              setTimeout(() => {
                questionTab.click();
              }, 800);
            },
            1000,
            9000
          );
        }

        return (
          <Suspense fallback={<div />}>
            <LazyWidget productInfo={productInfo} />
          </Suspense>
        );

      case 'cart':
        return <CartDrawer info={cartInfo} productCases={productCases} caseText={caseText} caseSubheader={caseSubheader} />;

      case 'returnMoney':
        return <ReturnMoneyBack returnMoneyBack={returnMoneyBack}/>;

      default:
        return;
    }
  };

  const close = () => {
    closeReviews();

    if (isSSR) return;

    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
  };

  return (
    <>
    <div id="backdrop-container">
      <Backdrop open={drawerOpen} onClick={drawerOpen ? close : undefined}>
        {drawerOpen && (
          <BackdropTitle>
            <StyledIcon name="arrow-left" />
            Back to site
          </BackdropTitle>
        )}
      </Backdrop>
      </div>
      <div id="drawer-container">
      <Drawer open={drawerOpen} ref={drawerRef} drawerType={drawerType}>
        <CloseIcon name="close" onClick={close} aria-label="Close Menu"/>
        {drawerOpen && !isSSR && renderContent(drawerType)}
      </Drawer>
      </div>
    </>
  );
};

export default SideDrawer;
