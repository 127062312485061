import uk from "./uk.json";
import us from "./us.json";

const currentLocale = process.env.GATSBY_CURRENCY_SYMBOL || "$";

const languages = {
  "$": us,
  "£": uk,
};

const locale = languages[currentLocale];

export default locale;