import { useStaticQuery, graphql } from "gatsby";

const useHeaderData = () => {
  return useStaticQuery(graphql`
    query HeaderQuery {
      headerMenu: sanityMenus {
        items {
          ... on SanityExternalLink {
            externalLink: link
            title
          }
          ... on SanityInternalLink {
            link {
              ... on SanityPage {
                content {
                  main {
                    title
                    slug {
                      current
                    }
                  }
                }
              }
              ... on SanityProduct {
                content {
                  main {
                    title
                    slug {
                      current
                    }
                  }
                }
              }
            }
            title
          }
        }
      }

      logo: sanityLogoImage {
        id
        image {
          asset {
            url
          }
        }
        link
      }
    }
  `);
};

export default useHeaderData;
