import styled, { css } from 'styled-components';
import CartUpsells from '../CartUpsells';
import Button from '../Common/Button';
import { SectionHeading } from '../Text';
import ProductList from '../ProductList';

interface EmptyProps {
  empty: boolean;
}

interface WidthProps {
  width: number;
}

interface CaseProps {
  isActive: boolean;
  increaseHeight: boolean;
}

interface IsActiveProps {
  isActive: boolean;
}

export const Container = styled.div<EmptyProps>`
  /* max-height: calc(100%); */
  margin-right: -2rem;
  padding-right: 2rem;
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */

  ${({ empty }) =>
    !empty &&
    css`
      /* max-height: calc(100% - 13.8rem); */

      @media ${({ theme }) => theme.device.tablet} {
        overflow-y: scroll;
        max-height: calc(100% - 21.3rem);
        box-sizing: content-box;
      }
    `}

    @media ${({ theme }) => theme.device.tablet} {
      overflow-y: scroll;
      margin-right: -5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  position: sticky;
  transform: translateX(-2rem) translateY(2rem);
  /* bottom: -2rem; */
  width: calc(100% + 4rem);
  bottom: 0;
  /* margin-left: -2rem; */
  padding: 2rem;
  /* box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.1); */
  /* box-shadow: ${({ open }) =>
    open ? '0 0 1.2rem rgba(0, 0, 0, 0.1)' : 'none'}; */
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.pale_blue};
  z-index: 3;

  @media ${({ theme }) => theme.device.tablet} {
    position: absolute;
    transform: none;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4rem 5rem;
    min-height: 20.2rem;
  }
`;

export const Subeading = styled(SectionHeading)`
  font-size: 1.2rem;
  line-height: 1.4rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const Heading = styled(SectionHeading)`
  font-size: 2.8rem;
  line-height: 3.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3.4rem;
    line-height: 4.4rem;
  }
`;

export const FooterRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  &#multibuyDiscount {
    display: none;
  }
`;

export const StyledUpsells = styled(CartUpsells)`
  margin-top: 3rem;
`;

export const CheckoutButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
  border-radius: 4px;
`;

export const EmptyContainer = styled.div`
  padding-top: 6rem;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 10rem;
    align-items: center;
  }
`;

export const EmptyHeading = styled(SectionHeading)`
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 3.6rem;
    line-height: 4.2rem;
    margin-bottom: 0.6rem;
  }
`;

export const EmptySubheading = styled(SectionHeading)`
  display: none;

  @media ${({ theme }) => theme.device.tablet} {
    display: block;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-bottom: 6.4rem;
  }
`;

export const StyledProductList = styled(ProductList)`
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const EmptyCheckoutButton = styled(Button)`
  width: 100%;
  margin: 3.8rem 0 0;
  justify-content: center;
`;

export const ProgressBarSubheading = styled(SectionHeading)`
  font-size: 1.2rem;
  line-height: 3rem;
  z-index: 2;
  position: relative;
  text-align: center;
`;

export const ProgressContainer = styled.div`
  /* position: relative; */
`;

export const ProgressBar = styled.div<WidthProps>`
  background-color: #eaeef5;
  /* display: none; */
  opacity: 0.5;
  height: 3rem;
  /* padding: 0 2rem; */
  width: 100%;
  position: absolute;
  left: 0;
/* 
  @media ${({ theme }) => theme.device.tablet} {
    display: block;
  }
 */
  &:before {
    content: "";
    position: absolute;
    background-color: #d5dcea;
    width: ${({ width }) => `${width}%`};
    height: 3rem;
    left: 0;
  }
`;

export const FreeShippingHeading = styled(SectionHeading)`
  font-size: 1.4rem;
  line-height: 1.6rem;
`;

export const CaseHeader = styled.div`
  display: flex;
  padding: 28px 0 0px;
  font-family: 'Effra-Regular';
  font-size: 22px;
  align-items: flex-start;
  @media ${({ theme }) => theme.device.tablet} {
    padding: 28px 0 5px;
  }

  span {
    margin-bottom: 13px;
  }

  .header-subheader {
    display: flex;
    flex-direction: column;

    strong {
      font-weight: bold;
    }
  }
`;

export const CaseSubheader = styled.div`
  padding: 0;
  font-family: 'Effra-Regular';
  font-size: 14px;
  line-height: 1;
  align-items: center;

  strong {
    font-weight: bold;
  }
`;

export const CaseContainer = styled.div<CaseProps>`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 12px;
  margin: 2rem 0;

  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const IsActive = styled.img<IsActiveProps>`
  transform: ${(props) => (props.isActive ? 'rotate(180deg)' : '')};
  width: 33px;
  height: 16px;
  transition: 0.2s all ease;
`;

export const CaseAccordionHeader = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DiscountCodeText = styled.span`
  color: #313131;
  font-size: 1.4rem;
  font-weight: 500;

  span {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

export const RemoveDiscountBtn = styled.button`
  background: transparent;
  border: none;
  svg {
    width: 15px;
    cursor: pointer;
  }
`;

export const TagIcon = styled.span`
  svg {
    width: 25px;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 20px;

  @media ${({ theme }) => theme.device.smTablet} {
    flex-direction: column;
  }
`;

export const TagWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff8de;
  border-color: #f0e4bc;
  color: #545454;
  border-radius: 5px;
  padding: 1em;
  gap: 10px;

  svg {
    width: 20px;
  }
`;

export const Code = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
`;

export const WarningText = styled.p`
  font-size: 1.3rem;
`;  

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: rgba(111,111,111,0.11);
  color: #6f6f6f;
  padding: 0.8571428571em;
  font-size: 0.8571428571em;
  border-radius: 4px;
  width: fit-content;
`;

interface DiscountInterface {
  error: boolean;
}

export const DiscountInput = styled.input<DiscountInterface>`
  background-color: white;
  color: #333333;
  border: 1px transparent solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.9285714286em 0.7857142857em;
  margin-left: 2px;
  word-break: normal;
  line-height: inherit;
  padding: 5px 30px;
  border-color: ${(props) => (props.error ? "#f7373d" : "#d2d2d2")};
  box-shadow: ${(props) => props.error ? "0 0 0 1px #f7373d" : "0"}
`;

export const DiscountError = styled.p`
  color: #f7373d;
  margin: 0.5714285714em 0 1.2857142857em;
  font-size: 1.3rem;
  line-height: 1.3rem;
`;

export const DiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 30px;
`;

export const DiscountForm = styled.form`
  display: flex;
  flex-direction: row;
  width: fit-content;
`

export const AddDiscountButton = styled.button`
  background: #02498b;
  color: white;
  width: auto;
  margin-left: 0.8571428571em;
  padding: 1.4em 1.7em;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px transparent solid;
  font-weight: 500;
  font-size: 1.4rem;

  &:hover {
    background: #012f59;
    cursor: pointer;
  }

  &:disabled {
    background: #c6c6c6;
  }
`;

export const Loader = styled.div`
  &, &:after {
    border-radius: 50%;
    width: 7em;
    height: 7em;
  }
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const GridUpsells = styled.div``;

export const DiscountMessage = styled(SectionHeading)`
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;