import React from "react";
import { NavContainer, StyledLink } from "./styles";

interface Props {
  menu: [];
  marginTop: boolean;
}

const Nav = ({ menu, marginTop }: Props) => {
  return (
    <NavContainer marginTop={marginTop}>
      {menu.map((item: any, index) => (
        <StyledLink
          color="font_white"
          key={index}
          to={
            item.link ? item.link: 
            item.externalLink
              ? item.externalLink
              : `/${item.link?.content.main.slug.current}`
          }
        >
          {item.title}
        </StyledLink>
      ))}
    </NavContainer>
  );
};

export default Nav;
