import styled from "styled-components";
import Button from "../Common/Button";
import { SectionHeading } from "../Text";

interface WhatsappProps {
  enabled?: boolean;
}


export const Block = styled.div`
  background-color: ${({ theme }) => theme.colors.darker_pale_blue};
  width: 100%;
  padding: 3.5rem 0 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    padding: 6rem;
  }
`;

export const Heading = styled(SectionHeading)`
  line-height: 5.4rem;
  margin-bottom: 1.2rem;
  text-align: center;

  @media ${({ theme }) => theme.device.tablet} {
    text-align: unset;
  }
`;

export const StyledButton = styled(Button)<WhatsappProps>`
  justify-content: center;
  border-radius: ${({enabled}) => enabled ? "10px" : "0"};
  @media ${({ theme }) => theme.device.phone} {
    margin: auto;

  }
`;

export const WhatsappContainer = styled.div`
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  padding: 10px 0 0 0;
  background-color: ${({ theme }) => theme.colors.darker_pale_blue};
  border-radius: 0;
  width: 60%;
  margin: auto;
  display: flex;

  @media ${({ theme }) => theme.device.tablet} { 
    width: 100%;
    border-radius: 10px 10px 10px 10px;
  }

`;

export const WhatsappBtn = styled.div`
  width: 90%;
  height: auto;
  margin: 1rem auto auto auto;

  @media ${({ theme }) => theme.device.tablet} {  
    margin: auto;
    width: 100%;
    margin: 1rem auto auto auto;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
  }
`;

export const BlockAndWhatsapp = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media ${({ theme }) => theme.device.phone} {  
    width: unset;
  }

`;