import styled from "styled-components";
import { Paragraph, SectionHeading } from "../Text";
import Icon from "src/components/Common/Icon";
import Image from "../Common/ImageFluid";
import PageLink from "../Link";

interface DrawerProps {
  open: boolean;
}

export const Drawer = styled.div<DrawerProps>`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.pale_blue};
  position: fixed;
  top: 0;
  width: 87.5vw;
  left: 0;
  z-index: 200;
  /* box-shadow: ${({ open }) =>
    open ? "1px 0px 7px rgba(0, 0, 0, 0.5)" : "none"}; */
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); */
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-out;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const Backdrop = styled.div`
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  /* padding-left: 9.4rem; */
  padding-left: 5.5vw;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 72, 101, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
`;

export const Logo = styled(Image)`
  max-width: 7.3rem;
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  right: 2rem;
  top: 1.7rem;
  width: 1.7rem;
  height: 1.7rem;
`;

export const SearchIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  right: 5.8rem;
  top: 1.4rem;
  width: 2.4rem;
  height: 2.4rem;
`;

export const MainMenu = styled.ul`
  margin-top: 5.3rem;
`;
// export const NewMainMenu = styled.ul`
//   margin-top: 5.3rem;
// `;

export const MainItem = styled.li`
  list-style-type: none;
  margin: 0 0 2rem 0;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MainLink = styled(PageLink)`
  font-size: 2rem;
  line-height: 3.14rem;
`;

export const MainDescription = styled(Paragraph)`
  font-size: 1.2rem;
  line-height: 1.9rem;
`;

export const SecondaryMenu = styled.ul`
  margin-top: 5rem;
`;

export const SecondaryItem = styled.li`
  list-style-type: none;
  margin: 0 0 2rem 0;
  padding: 0;
`;

export const SecondaryLink = styled(PageLink)`
  font-size: 1.4rem;
  line-height: 1.6rem;
`;

export const MobileFooter = styled.div`
  margin-top: auto;
  margin-left: auto;
`;

export const FooterLink = styled(PageLink)`
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
`;

export const ArrowRight = styled(Icon)`
  margin-left: 0.5rem;
`;
