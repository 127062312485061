import styled from "styled-components";
import Image from "../Common/ImageFluid";
import { SectionHeading } from "../Text";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bg_white};

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
    // max-height: 14.5rem;
  }

  .hide-mobile {
    display: none;
    @media ${({ theme }) => theme.device.tablet} { 
      display: block;
    }
  }

  .hide-desktop {
    display: block;
    font-size: 14px;

    h2 {
      margin-botton: 0;
    }
    @media ${({ theme }) => theme.device.tablet} { 
      display: none;
    }
  }
`;

export const Title = styled(SectionHeading)`
  margin-bottom: 0.9rem;
  font-size: 1.6rem;
  line-height: 2.2rem;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 0.6rem;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`;

export const ItemImg = styled.img`
  width: 100%;
  display: none;

  @media ${({ theme }) => theme.device.tablet} {
    display: block !important;
    max-width: 20rem;
    max-height: 14.5rem;
    object-fit: contain;
  }
`;

export const InfoBox = styled.div`
  padding: 1rem 2rem 1rem 1.3rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    padding: 4rem;
    flex-wrap: unset;
  }
`;

export const TextBlock = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.device.tablet} {
    width: 70%;
    margin-bottom: unset;
  }
`;

export const Description = styled(SectionHeading)`
  font-size: 1.2rem;
  line-height: 1.4rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;

  @media ${({ theme }) => theme.device.tablet} { 
    margin-right: 0rem;
    margin-left: 0rem;
  }
  @media ${({ theme }) => theme.device.largeDesktop} {
    margin-left: auto;
    margin-right: 4.3rem;
  }
  /* margin-right: 1.6rem; */
`;

export const NumberInput = styled.input`
  -moz-appearance: textfield;
  border: 0;
  padding: 0 0 0 0.9rem;
  min-width: 1.6rem;
  width: 0;
  box-sizing: content-box;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 0;
  }
`;

export const QuantityButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;
`;

export const Price = styled(SectionHeading)`
  font-size: 1.8rem;
  line-height: 2.6rem;
  /* margin-left: auto; */
`;

export const StyledImage = styled(Image)`
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    max-width: 20rem;
    max-height: 14.5rem;
    object-fit: cover;
  }
`;

export const QuantityAndPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} { 
    flex-direction: column;
    width: 30%
  }
  @media ${({ theme }) => theme.device.largeDesktop} { 
    flex-direction: row;
    width: 30%
  }
`;