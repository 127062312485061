import React from "react";
import { StoreContextProvider } from "src/context/siteContext";
import { eventData } from "src/utils/dataLayer";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "src/theme/globalStyle";
import "./src/styles/fonts.css";

const geoRedirect = async () => {
  const results = await fetch("/api/geo");
  const geo = await results.json();
  const isUK = process.env.GATSBY_SANITY_DATASET === "production";

  if (typeof window !== "undefined") {
    if ((geo.country == "US" && isUK) || (geo.country == "GB" && !isUK)) {
      window.openRedirectModal = true;
    } else {
      window.openRedirectModal = false;
    }
  }
};

export const onClientEntry = async () => {
  try {
    await geoRedirect();
  } catch (e) {
    console.log(e);
  }
};

export const onRouteUpdate = ({ location, previousLocation }) => {
  // app.analytics.pageview(location);
  window.dataLayer?.push({
    ...eventData("dl_user_data")
  });
  if (location && location.hash) {
    let headerHeight;
    const notificationBar = document.getElementById("notification-bar");
    const header = document.getElementById("header");
    const item = document.getElementById(location.hash)?.offsetTop;

    if (!item) return;

    if (!notificationBar) {
      headerHeight = header.offsetHeight;
    }

    headerHeight = header.offsetHeight + notificationBar.offsetHeight;

    window.scrollTo({
      top: item - headerHeight,
      behavior: "smooth"
    });
  }
  return true;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  // if (location.action === "PUSH") {
  //   window.setTimeout(() => window.scrollTo(0, 0), 600);
  // } else {
  //   const savedPosition = getSavedScrollPosition(location);
  //   window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 600);
  // }
  return true;
};

// Optional Config Sentry
// Sentry.init({ dsn: process.env.GATSBY_SENTRY_DSN });

export const wrapPageElement = ({ element }) => (
  <StoreContextProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  </StoreContextProvider>
);
