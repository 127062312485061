import styled from "styled-components";
import SanityImage from "gatsby-plugin-sanity-image";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledImage = styled(GatsbyImage)`
  max-height: 100%;

  &.gatsby-image-wrapper [data-main-image] {
    transition: all;
  }
`;

export const StyledSvg = styled(SanityImage)`
  max-width: 100%;
`;
