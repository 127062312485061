import React from 'react';
import { AdditionalInformationContainer, ImageWrapper } from './styles';
import Image from 'src/components/Common/ImageFluid';

interface Props {
  additionalInformationImage: {};
}

const AdditionalInformation = ({ additionalInformationImage }: Props) => {
  return (
    <AdditionalInformationContainer>
      <ImageWrapper>
        <Image imageRef={additionalInformationImage} />
      </ImageWrapper>
    </AdditionalInformationContainer>
  );
};

export default AdditionalInformation;
