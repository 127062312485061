import styled, { css } from "styled-components";
import { SectionHeading } from "../Text";
import BlockContent from "../../components/BlockContent";

export const EmptyContainer = styled.div`
  padding: 12rem 20rem 0rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .bold {
      font-weight: bold;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding-top: 12rem;
    padding-right: 20rem;
    align-items: left;
  }

  @media ${({ theme }) => theme.device.phone} {
    padding-top: 12rem;
    padding-right: 0rem;
    padding-left: 0rem;
    align-items: left;
  }
`;

export const EmptyHeading = styled(BlockContent)`
  font-family: 'Effra-Regular';
  h2 {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 0.8rem;
    margin-top: 20px;
    font-weight: 400;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 4rem;
      line-height: 4.2rem;
      margin-bottom: 4rem;
    }
  }

  p {
    @media ${({ theme }) => theme.device.tablet} {
      display: block;
      font-size: 1.8rem;
      line-height: 2.6rem;
      margin-bottom: 6.4rem;
    }
    
    @media ${({ theme }) => theme.device.phone} {
      font-size: 1.2rem;
      line-height: 3.2rem;
    }
  } 
`;
