import React, { useEffect } from 'react';
import { List, StyledProduct } from './styles';
import { Product } from '../../interfaces/sanity';

interface Props {
  products: {
    product: Product;
    productDescription: any;
  };
  buttonsInBlack?: boolean;
  firstLarge?: boolean;
  buttonColors?: object;
  buttonBackgroundColors?: object;
  mobileGrid?: boolean;
  className?: string;
  addToCart: boolean;
  onClick?: () => void;
  withDescription?: boolean;
  showPrice?: boolean;
  showRating?: boolean;
  isCollection?: boolean;
}

const ProductList = ({
  products,
  buttonsInBlack = false,
  firstLarge = false,
  mobileGrid = false,
  addToCart = false,
  className,
  buttonColors, 
  buttonBackgroundColors,
  withDescription = false,
  showPrice = true,
  showRating,
  onClick,
  isCollection
}: Props) => {
  const filteredProducts = withDescription
    ? products.map((item) => item.product)
    : products;

  useEffect(() => {
    const items = filteredProducts?.map((prod: any, idx: number) => {
      return {
        item_id: prod?.content?.shopify?.defaultVariant?.sku,
        item_name: prod?.content?.shopify?.title,
        currency: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
        index: idx + 1,
        item_brand: "GroovePillows",
        item_category: "Pillows",
        item_variant:  prod?.content?.shopify?.defaultVariant?.title,
        price: prod?.content?.shopify?.defaultVariant?.price,
      }
    })
    dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        list_name: "Collections",
        items
      }
    });
  }, []);


  return (
    <List firstLarge={firstLarge} mobileGrid={mobileGrid} className={className}>
    {filteredProducts?.map((item, i) => {
        const product = item?.product ? item.product : item;
        return (
          <StyledProduct
            id={product?.content?.shopify?.productId}
            key={product._id}
            slug={product.content.main.slug.current}
            title={product.content.main.title}
            okendoReviews={product?.content?.main?.okendoReviews}
            // image={product.content.main.imageCover}
            image={product.content.shopify?.image}
            isCollection={isCollection}
            price={product.content.shopify?.defaultPrice}
            ribbon={
              product.content.main.ribbon?.enabled
                ? product.content.main.ribbon?.text
                : undefined
            }
            addToCart={addToCart}
            large={firstLarge && i === 0 ? true : false}
            description={products[i].productDescription}
            mobilePhoto={
              product.content.main.mobileGallery?.length > 0 &&
              product.content.main.mobileGallery[0]
            }
            showPrice={showPrice}
            showRating={showRating}
            onClick={onClick}
            buttonColors={buttonColors}
            buttonBackgroundColors={buttonBackgroundColors}
            newProductMessage={product?.content?.main?.newProductMessage}
            productQuantity={product?.content?.main?.productQuantity?.[0]}
            label={item?.text}
            labelTxtColor={item?.textColor}
            labelBgColor={item?.bgColor}
            buttonsInBlack={buttonsInBlack}
          />
        );
      })}
    </List>
  );
};

export default ProductList;
