import React, { useEffect, useRef } from "react";
import {
  useStore,
  useMobileMenu,
  useSearchDrawer,
} from "src/context/siteContext";
import {
  Drawer,
  Backdrop,
  CloseIcon,
  SearchIcon,
  Logo,
  MainMenu,
  // NewMainMenu,
  MainItem,
  MainLink,
  MainDescription,
  SecondaryMenu,
  SecondaryItem,
  SecondaryLink,
  MobileFooter,
  FooterLink,
  ArrowRight,
} from "./styles";
import { useTheme } from "styled-components";
import { isSSR } from "src/utils/ssr";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { Link } from "gatsby"


const SideDrawer = ({ data }) => {
  const theme = useTheme();
  const { mobileMenuOpen } = useStore();
  const { openSearch } = useSearchDrawer();
  const menuRef = useRef(null);

  const { closeMenu } = useMobileMenu();

  useEffect(() => {
    if (isSSR || !menuRef.current) return;

    if (mobileMenuOpen) {
      document.documentElement.style.overflow = "hidden";

      disableBodyScroll(menuRef.current);
    } else {
      document.documentElement.style.overflow = "auto";

      enableBodyScroll(menuRef.current);
    }

    return () => clearAllBodyScrollLocks();
  }, [mobileMenuOpen]);

  const openSearchDrawer = () => {
    closeMenu();
    openSearch();
  };

  return (
    <>
      {mobileMenuOpen && <Backdrop onClick={closeMenu} />}
      <Drawer open={mobileMenuOpen} ref={menuRef}>
        <CloseIcon name="close-thick" onClick={closeMenu} aria-label="Close menu"/>
        <SearchIcon
          name="search"
          color={theme.colors.font_black}
          onClick={openSearchDrawer}
        />
        <Link to="/" onClick={closeMenu} aria-label="Close menu">
        <Logo imageRef={data.logo} />
        </Link>

        <MainMenu id="mainMenu">
          {data.mainMenu.map((item) => (
            <MainItem key={item._key}>
              <MainLink to={item.link} onClick={closeMenu} aria-label="View item">
                {item.title}
              </MainLink>
              {item.description && (
                <MainDescription>{item.description}</MainDescription>
              )}
            </MainItem>
          ))}
        </MainMenu>

        {/* <NewMainMenu id="NewMainMenu">
          {data?.mainMenu?.map((item) => (
            data?.subMenu?.find(i => i?.link === item?.link)
            ? ''
            :(<MainItem key={item._key}>
              <MainLink to={item.link} onClick={closeMenu} aria-label="View item">
                {item.title}
              </MainLink>
              {item.description && (
                <MainDescription>{item.description}</MainDescription>
              )}
            </MainItem>)
          ))}
        </NewMainMenu> */}

        <SecondaryMenu>
          {data.secondaryMenu.map((item) => (
            <SecondaryItem key={item._key}>
              <SecondaryLink to={item.link} onClick={closeMenu} aria-label="View item">
                {item.title}
              </SecondaryLink>
            </SecondaryItem>
          ))}
        </SecondaryMenu>

        <MobileFooter>
          <FooterLink to={data.storeLink} aria-label="View other pages">
            {data.storeTitle}
            <ArrowRight name="arrow-right" />
          </FooterLink>
        </MobileFooter>
      </Drawer>
    </>
  );
};

export default SideDrawer;
